// import React from 'react';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import {
//   AppBar,
//   Toolbar,
//   CssBaseline,
//   Typography,
//   useTheme,
//   useMediaQuery,
//   Button,
//   Menu,
//   MenuItem,
//   Divider
// } from '@mui/material';
// import { Link, useLocation } from 'react-router-dom';
// import DrawerComponent from './AlumniDrawer';
// import logo from './header.png';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import backgroundImage from '../../../assets/header1.jpg';
// import BDCH from './BDCH.png';

// // Define your custom theme
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#2360BD' // Set the primary color to your desired color
//     }
//   }
// });

// const buttonStyles = {
//   color: 'white',
//   fontSize: '18px',
//   '&:hover': {
//     color: '#000000',
//     backgroundColor: '#feffff'
//   }
// };

// const contactButtonStyles = {
//   ...buttonStyles,
//   marginRight: '40px',
//   backgroundColor: '#feffff',
//   color: '#2360BD'
// };

// function AlumniNavbar() {
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));
//   const location = useLocation();

//   // Function to determine if a button is active based on the current route
//   const isActive = (path) => {
//     return location.pathname === path;
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <div style={{ position: 'relative' }}>
//         <div style={{ margin: 0, padding: 0 }}>
//           <img
//             src={BDCH}
//             alt="Alumni"
//             style={{ width: '100%', height: 'auto', display: 'block' }}
//           />
//         </div>

//         <AppBar
//           position="static"
//           style={{
//             position: 'relative',
//             bottom: 0,
//             background: 'linear-gradient(90deg, #102C57 0%, #2360BD 96.43%)'
//           }}
//         >
//           <CssBaseline />
//           <Toolbar style={{ justifyContent: 'center', padding: '5px' }}>
//             {!isMobile && (
//               <div>
//                 <Button
//                   component={Link}
//                   to="/alumni"
//                   color="primary"
//                   sx={
//                     isActive('/alumni')
//                       ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
//                       : buttonStyles
//                   }
//                 >
//                   Home
//                 </Button>
//                 <Button
//                   component={Link}
//                   to="/alumni/directory"
//                   color="primary"
//                   sx={
//                     isActive('/alumni/directory')
//                       ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
//                       : buttonStyles
//                   }
//                 >
//                   Directory
//                 </Button>
//                 <Button
//                   component={Link}
//                   to="/alumni/membership"
//                   color="primary"
//                   sx={
//                     isActive('/alumni/membership')
//                       ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
//                       : buttonStyles
//                   }
//                 >
//                   Online Life Membership
//                 </Button>
//                 <Button
//                   component={Link}
//                   to="/alumni/news"
//                   color="primary"
//                   sx={
//                     isActive('/alumni/news')
//                       ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
//                       : buttonStyles
//                   }
//                 >
//                   News And Events
//                 </Button>
//                 <Button
//                   component={Link}
//                   to="/alumni/gallery"
//                   color="primary"
//                   sx={
//                     isActive('/alumni/gallery')
//                       ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
//                       : buttonStyles
//                   }
//                 >
//                   Event Gallery
//                 </Button>
//                 <Button
//                   component={Link}
//                   to="/alumni/contact"
//                   color="primary"
//                   sx={
//                     isActive('/alumni/contact')
//                       ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
//                       : buttonStyles
//                   }
//                 >
//                   Contact Us
//                 </Button>
//                 <Button
//                   color="primary"
//                   sx={
//                     isActive('/alumni/journal')
//                       ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
//                       : buttonStyles
//                   }
//                   onClick={() =>
//                     window.open('https://journals.lww.com/ijoh/pages/default.aspx', '_blank')
//                   }
//                 >
//                   Journal
//                 </Button>
//               </div>
//             )}
//           </Toolbar>
//         </AppBar>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default AlumniNavbar;

import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AppBar, Toolbar, CssBaseline, useMediaQuery, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import AlumniDrawer from './AlumniDrawer';
import BDCH from './BDCH.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2360BD'
    }
  }
});

const buttonStyles = {
  color: 'white',
  fontSize: '18px',
  '&:hover': {
    color: '#000000',
    backgroundColor: '#feffff'
  }
};

function AlumniNavbar() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  // Function to determine if a button is active based on the current route
  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: 'relative' }}>
        <div style={{ margin: 0, padding: 0 }}>
          <img
            src={BDCH}
            alt="Alumni"
            style={{ width: '100%', height: 'auto', display: 'block' }}
          />
        </div>

        <AppBar
          position="static"
          style={{
            position: 'relative',
            bottom: 0,
            background: 'linear-gradient(90deg, #102C57 0%, #2360BD 96.43%)'
          }}
        >
          <CssBaseline />
          <Toolbar style={{ justifyContent: isMobile ? 'right' : 'center', padding: '5px' }}>
            {isMobile ? (
              <AlumniDrawer />
            ) : (
              <div>
                <Button
                  component={Link}
                  to="/alumni"
                  color="primary"
                  sx={
                    isActive('/alumni')
                      ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
                      : buttonStyles
                  }
                >
                  Home
                </Button>
                <Button
                  component={Link}
                  to="/alumni/directory"
                  color="primary"
                  sx={
                    isActive('/alumni/directory')
                      ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
                      : buttonStyles
                  }
                >
                  Directory
                </Button>
                <Button
                  component={Link}
                  to="/alumni/membership"
                  color="primary"
                  sx={
                    isActive('/alumni/membership')
                      ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
                      : buttonStyles
                  }
                >
                  Online Life Membership
                </Button>
                <Button
                  component={Link}
                  to="/alumni/news"
                  color="primary"
                  sx={
                    isActive('/alumni/news')
                      ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
                      : buttonStyles
                  }
                >
                  News And Events
                </Button>
                {/* <Button
                  component={Link}
                  to="/alumni/gallery"
                  color="primary"
                  sx={
                    isActive('/alumni/gallery')
                      ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
                      : buttonStyles
                  }
                >
                  Event Gallery
                </Button> */}
                <Button
                  component={Link}
                  to="/alumni/contact"
                  color="primary"
                  sx={
                    isActive('/alumni/contact')
                      ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
                      : buttonStyles
                  }
                >
                  Contact Us
                </Button>
                <Button
                  color="primary"
                  sx={
                    isActive('/alumni/journal')
                      ? { ...buttonStyles, backgroundColor: '#feffff', color: '#2360BD' }
                      : buttonStyles
                  }
                  onClick={() =>
                    window.open('https://journals.lww.com/ijoh/pages/default.aspx', '_blank')
                  }
                >
                  Journal
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}

export default AlumniNavbar;
