import { lazy } from 'react';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import Bdch from './views/Bdch';
import navBarRoutes from './views/navBarRoutes';
import ScrollNavbar from './views/components/ScrollNavbar';
import { Navigate } from 'react-router-dom';
import Alumni from './views/Alumni';
import alumniRoutes from './views/alumniRoutes';
import IQAC from './views/IQAC';
import iqacRoutes from './views/iqacRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

const Department = Loadable(lazy(() => import('app/views/BackOfiice/Department')));
const News = Loadable(lazy(() => import('app/views/BackOfiice/news/NewsMain')));
const Faculty = Loadable(lazy(() => import('app/views/BackOfiice/faculty/FacultyView')));
const Publications = Loadable(lazy(() => import('app/views/BackOfiice/publications/Publications')));
const Presentations = Loadable(
  lazy(() => import('app/views/BackOfiice/presentation/Presentation'))
);
const Awards = Loadable(lazy(() => import('app/views/BackOfiice/awards/Awards')));
const Dissertation = Loadable(lazy(() => import('app/views/BackOfiice/dissertation/Dissertation')));
const Syllabus = Loadable(lazy(() => import('app/views/BackOfiice/syllabus/Syllabus')));
const AluminiDirectory = Loadable(
  lazy(() => import('app/views/BackOfiice/alumini/AluminiDirectory'))
);
const AluminiMemberShip = Loadable(
  lazy(() => import('app/views/BackOfiice/aluminiMembership/AluminiMemberShip'))
);
const AlumniNews = Loadable(lazy(() => import('app/views/BackOfiice/alumini/AlumniNewsNew')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));
const ContactUs = Loadable(lazy(() => import('app/views/BackOfiice/alumini/ContactUs')));
const BapContact = Loadable(lazy(() => import('app/views/BackOfiice/contactUs/BapContact')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'admin/dashboard',
        element: <Analytics />,
        auth: authRoles.admin
      },
      {
        path: 'admin/department',
        element: <Department />,
        auth: authRoles.admin
      },
      {
        path: 'admin/news',
        element: <News />,
        auth: authRoles.admin
      },
      {
        path: 'admin/department/faculty',
        element: <Faculty />,
        auth: authRoles.admin
      },
      {
        path: '/admin/department/Publications',
        element: <Publications />,
        auth: authRoles.admin
      },
      {
        path: '/admin/department/presentations',
        element: <Presentations />,
        auth: authRoles.admin
      },
      {
        path: '/admin/department/awards',
        element: <Awards />,
        auth: authRoles.admin
      },
      {
        path: '/admin/department/syllabus',
        element: <Syllabus />,
        auth: authRoles.admin
      },
      {
        path: '/admin/department/dissertation',
        element: <Dissertation />,
        auth: authRoles.admin
      },
      {
        path: '/admin/alumini/directory',
        element: <AluminiDirectory />,
        auth: authRoles.admin
      },
      {
        path: '/admin/alumini/membership',
        element: <AluminiMemberShip />,
        auth: authRoles.admin
      },
      {
        path: '/admin/alumni/news',
        element: <AlumniNews />,
        auth: authRoles.admin
      },
      {
        path: '/admin/alumni/contact',
        element: <ContactUs />,
        auth: authRoles.admin
      },
      {
        path: '/admin/contact',
        element: <BapContact />,
        auth: authRoles.admin
      }
    ]
  },

  // session pages route
  { path: '/admin/dashboard', element: <Analytics /> },
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  // { path: '/aboutus', element: <AboutUs /> },
  // { path: '/', element: <Navigate to="/" /> },
  { path: '/alumni', element: <Navigate to="/alumni/" /> },
  { path: '/iqac', element: <Navigate to="/iqac/" /> },

  // { path: '*', element: <NotFound /> }

  {
    path: '/',
    element: <Bdch />,
    children: [...navBarRoutes]
  },
  {
    path: '/alumni/',
    element: <Alumni />,
    children: [...alumniRoutes]
  },
  {
    path: '/iqac/',
    element: <IQAC />,
    children: [...iqacRoutes]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
