// import React, { useEffect, useState } from 'react';
// import { viewAlumniNews } from 'api-ws/alumniNews-ws';

// import {
//   Button,
//   Divider,
//   Grid,
//   List,
//   ListItem,
//   ListItemText,
//   Typography,
//   useMediaQuery,
//   Container
// } from '@mui/material';
// import moment from 'moment';
// import EventIcon from '@mui/icons-material/Event';
// import PersonIcon from '@mui/icons-material/Person';
// import { useTheme } from '@emotion/react';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import AHeader from '../components/AHeader';

// function ANews() {
//   const [newsv, setNewsv] = useState([]);
//   const fetchNews = () => {
//     viewAlumniNews()
//       .then((res) => {
//         // Once the data is fetched, update the state
//         console.log(res.data.alumniNews);
//         setNewsv(res.data.alumniNews);
//       })
//       .catch((error) => {
//         // Handle any errors
//         console.error('Error fetching news:', error);
//       });
//   };

//   useEffect(() => {
//     fetchNews();
//   }, []);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));

//   const openPDF = (pdfUrl) => {
//     var win = window.open();
//     win.document.write(
//       '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
//     );
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       sx={{
//         marginTop: '50px',
//         marginLeft: isMobile ? '1px' : '10px',
//         marginRight: '20px',
//         textAlign: 'center',
//         marginBottom: '30px'
//       }}
//     >
//       <Grid item xs={12}>
//         <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: isMobile ? '24px' : '34px' }}>
//           News & Events
//         </Typography>
//         <Divider
//           sx={{
//             marginTop: '35px',
//             marginBottom: '30px',
//             marginLeft: { md: '50px' },
//             width: { xs: '90%', md: '90%' }
//           }}
//         />
//       </Grid>
//       <Container>
//         <Grid style={{ height: 'auto', marginLeft: '40px', paddingRight: isMobile ? '40px' : 0 }}>
//           <List sx={{ width: '100%' }}>
//             {newsv?.map((item, index) => (
//               <React.Fragment key={index}>
//                 <ListItem alignItems="flex-start">
//                   <ListItemText
//                     primary={
//                       <React.Fragment>
//                         <Typography variant="h6" gutterBottom style={{ marginBottom: '10px' }}>
//                           {item?.title}
//                         </Typography>
//                         <Typography
//                           variant="body1"
//                           gutterBottom
//                           style={{ marginBottom: '10px' }}
//                           overflow="auto"
//                         >
//                           {item?.description}
//                         </Typography>
//                         <Typography
//                           variant="body2"
//                           color="textSecondary"
//                           display="flex"
//                           alignItems="center"
//                         >
//                           <EventIcon />
//                           <span style={{ marginRight: '6vh', marginLeft: '1vh' }}>
//                             {moment(item?.createdDate).format('DD MMM YYYY')}
//                           </span>

//                           <PersonIcon />

//                           <span style={{ marginLeft: '1vh', marginRight: '6vh' }}>
//                             {item?.newsaddedby}
//                           </span>

//                           {item?.pdfFiles && (
//                             <Button
//                               onClick={() => openPDF(item?.pdfFiles)}
//                               style={{ color: '#808080' }}
//                             >
//                               <PictureAsPdfIcon />
//                               <span style={{ marginLeft: '1vh', marginRight: '6vh' }}>
//                                 Click Here
//                               </span>
//                             </Button>
//                           )}
//                         </Typography>
//                       </React.Fragment>
//                     }
//                   />
//                 </ListItem>
//                 {index < newsv.length - 1 && (
//                   <Divider
//                     sx={{
//                       width: '70vw',
//                       height: '0.5px'
//                     }}
//                   />
//                 )}
//               </React.Fragment>
//             ))}
//           </List>
//         </Grid>
//       </Container>
//     </Grid>
//   );
// }

// export default ANews;

import React, { useEffect, useState } from 'react';
import { viewAlumniNews } from 'api-ws/alumniNews-ws';
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  Container,
  Box
} from '@mui/material';
import moment from 'moment';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@emotion/react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BDCH_GIF from 'assets/BDCH_GIF.gif';

function ANews() {
  const [newsv, setNewsv] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNews = () => {
    setLoading(true);
    viewAlumniNews()
      .then((res) => {
        console.log(res.data.alumniNews);
        setLoading(false);
        setNewsv(res.data.alumniNews);
      })
      .catch((error) => {
        console.error('Error fetching news:', error);
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{
        marginTop: '50px',
        marginLeft: isMobile ? '1px' : '10px',
        marginRight: '20px',
        textAlign: 'center',
        marginBottom: '30px',
        width: '100%'
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: isMobile ? '24px' : '34px' }}>
          News & Events
        </Typography>
        <Divider
          sx={{
            marginTop: '35px',
            marginBottom: '30px',
            marginLeft: { md: '50px' },
            width: { xs: '90%', md: '90%' }
          }}
        />
      </Grid>
      <Container>
        <Grid style={{ height: 'auto', marginLeft: '40px', paddingRight: isMobile ? '40px' : 0 }}>
          <List sx={{ width: '100%' }}>
            {newsv?.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '10px' }}>
                          {item?.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ marginBottom: '10px' }}
                          overflow="auto"
                        >
                          {item?.description}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: 'center',
                            gap: '8px',
                            marginTop: isMobile ? '8px' : 0
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              flexWrap: 'wrap'
                            }}
                          >
                            <EventIcon />
                            <Typography variant="body2" color="textSecondary">
                              {moment(item?.createdDate).format('DD MMM YYYY')}
                            </Typography>
                            <PersonIcon />
                            <Typography variant="body2" color="textSecondary">
                              {item?.newsaddedby}
                            </Typography>
                          </Box>
                          {item?.pdfFiles && (
                            <Button
                              onClick={() => openPDF(item?.pdfFiles)}
                              sx={{
                                color: '#808080',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <PictureAsPdfIcon />
                              <span>Click Here</span>
                            </Button>
                          )}
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {index < newsv.length - 1 && (
                  <Divider
                    sx={{
                      width: '70vw',
                      height: '0.5px'
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Container>
    </Grid>
  );
}

export default ANews;
