import React, { useEffect } from 'react';
import pdf from './Facilities and Services.pdf';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Grid, Box } from '@mui/material';

function Services(servicesData) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openPDF = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      `<iframe src="${pdfUrl}" style="width:100%; height:100%; border:none;"></iframe>`
    );
  };

  return (
    <Box
      sx={{
        marginTop: '20px',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box sx={{ width: '100%', overflowX: isMobile ? 'auto' : 'hidden' }}>
        {pdf &&
          (isMobile ? (
            <Button onClick={() => openPDF(pdf)}>
              {' '}
              Facilities and Services? <br />
              Please Click here
            </Button>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                padding: '4vh',
                margin: '0 auto'
              }}
            >
              <iframe
                title="Library PDF"
                src={pdf}
                width="900px"
                height="800px"
                style={{ border: 'none' }}
              />
            </Grid>
          ))}
      </Box>
    </Box>
  );
}

export default Services;
