import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addNews = (newsRequest, authToken) => {
  const urlPath = `/news/addNews`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: newsRequest,
    additionalHeader: additionalHeader
  });
};

export const updateNews = (newsRequest, authToken) => {
  const urlPath = `/news/updateNews`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: newsRequest,
    additionalHeader: additionalHeader
  });
};

export const viewNews = () => {
  const urlPath = `/news/getnewsDetails`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const removeNewsById = (id, authToken) => {
  const urlPath = `/news/removeNews/${id}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};
