import {
  useMediaQuery,
  Grid,
  Typography,
  useTheme,
  Divider,
  CardContent,
  Card,
  CardActionArea
} from '@mui/material';
import { useParams } from 'react-router-dom';
import galleryDataJson from './alumniEventGallery.json';
import { useState } from 'react';
import { useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';

function AlumniGalleryEvent() {
  const { id } = useParams();
  // console.log('id', id);
  // console.log('gallery', galleryDataJson?.[id]);

  const galleryData = galleryDataJson?.[id];
  const srcArray = galleryData?.map((item) => item.src);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Grid container spacing={2}>
        {galleryData?.map((image, index) => (
          <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
            <Card
              sx={{
                maxWidth: 320,
                margin: '10px',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
                }
              }}

              // onClick={() => handleCardClick(image.title)}
            >
              <CardActionArea>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <img
                    src={image.src}
                    onClick={() => openImageViewer(index)}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      margin: '2px'
                    }}
                    alt={image.alt}
                  />
                  <Typography>{image.alt}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {isViewerOpen && (
        <ImageViewer
          src={srcArray}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
}

export default AlumniGalleryEvent;
