// ScrollNavbar.js
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  CssBaseline,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DrawerComponent from './DrawerComponent';
import logo from './baplogo.png';
// import pdf1 from './prospectus.pdf';
import nirf from './nirf1.pdf';
import colorConfig from 'app/color/colorConfig';
import colorConfig2 from 'app/color/colorConfig2';
import colorConfig3 from 'app/color/colorConfig3';

const { backgroundColor, navbarColor, textColor } = colorConfig2;

const buttonStyles = {
  color: '#FFFFFF',
  '&:hover': {
    color: textColor,
    backgroundColor: 'transparent'
  }
};

const ScrollNavbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // let { id } = useParams  ();

  // let colorConfigs;
  // const idAsNumber = parseInt(id);
  // if (idAsNumber === 1) {
  //   colorConfigs = colorConfig;
  // } else if (idAsNumber === 2) {
  //   colorConfigs = colorConfig2;
  // } else if (idAsNumber === 3) {
  //   colorConfigs = colorConfig3;
  // } else {
  //   colorConfigs = colorConfig;
  // }

  const [isScrolled, setIsScrolled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [navbarWidth, setNavbarWidth] = useState('70%');
  const [navbarMargin, setNavbarMargin] = useState('15%');

  const [aboutUsAnchorEl, setAboutUsAnchorEl] = React.useState(null);
  const [administrationAnchorEl, setAdministrationAnchorEl] = React.useState(null);
  const [academicAnchorEl, setAcademicAnchorEl] = React.useState(null);
  const [departmentAnchorEl, setDepartmentAnchorEl] = React.useState(null);
  const [galleryAnchorEl, setGalleryAnchorEl] = React.useState(null);

  // Function to handle opening the dropdown menu
  const handleMenuToggle = (setAnchorEl, event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const getDropdownIcon = (anchorEl) => {
    return anchorEl ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      setIsScrolled(scrollTop > 0);
      setIsExpanded(scrollTop > 0);

      setNavbarWidth(scrollTop > 0 ? '100%' : '70%');
      setNavbarMargin(scrollTop > 0 ? '0%' : '15%');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={isScrolled ? 4 : 0}
        style={{
          background: `linear-gradient(90deg, #0352A1 0.01%, #0180A6 45.1%, #00B4AC 100%)`,
          width: isMobile ? '100%' : navbarWidth,
          transition: isMobile
            ? 'none'
            : 'width 0.5s ease-in-out, margin 0.5s ease-in-out, top 0.5s ease-in-out',
          marginLeft: isMobile ? '0%' : navbarMargin,
          marginRight: isMobile ? '0%' : navbarMargin,
          marginTop: isMobile ? '0' : isScrolled ? '0' : '90px'
        }}
      >
        <CssBaseline />
        <Toolbar sx={{ justifyContent: 'space-between', paddingRight: '10px' }}>
          <Typography variant="h6" component="div">
            <img src={logo} alt="College Logo" style={{ height: '50px' }} />
          </Typography>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <Toolbar style={{ justifyContent: 'center' }}>
              <div>
                <Button component={Link} to="/" color="inherit" sx={buttonStyles}>
                  Home
                </Button>

                <Button
                  color="inherit"
                  onClick={(event) => handleMenuToggle(setAboutUsAnchorEl, event)}
                  sx={buttonStyles}
                  endIcon={getDropdownIcon(aboutUsAnchorEl)}
                >
                  About Us
                </Button>
                <Menu
                  anchorEl={aboutUsAnchorEl}
                  open={Boolean(aboutUsAnchorEl)}
                  onClose={() => setAboutUsAnchorEl(null)}
                >
                  <MenuItem component={Link} to="/aboutUs" onClick={() => setAboutUsAnchorEl(null)}>
                    About Us
                  </MenuItem>
                  <MenuItem>Prospectus</MenuItem>
                  {/* onClick={() => openPDF(pdf1)} */}
                </Menu>

                <Button
                  color="inherit"
                  onClick={(event) => handleMenuToggle(setAdministrationAnchorEl, event)}
                  sx={buttonStyles}
                  endIcon={getDropdownIcon(administrationAnchorEl)}
                >
                  Administration
                </Button>
                <Menu
                  anchorEl={administrationAnchorEl}
                  open={Boolean(administrationAnchorEl)}
                  onClose={() => setAdministrationAnchorEl(null)}
                >
                  <MenuItem
                    component={Link}
                    to="/administration/management"
                    onClick={() => setAdministrationAnchorEl(null)}
                  >
                    Management
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/administration/principal"
                    onClick={() => setAdministrationAnchorEl(null)}
                  >
                    Principal
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/administration/affiliation"
                    onClick={() => setAdministrationAnchorEl(null)}
                  >
                    Affiliation
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/administration/committees"
                    onClick={() => setAdministrationAnchorEl(null)}
                  >
                    Committees
                  </MenuItem>

                  <MenuItem
                    component={Link}
                    to="/administration/rti"
                    onClick={() => setAdministrationAnchorEl(null)}
                  >
                    RTI
                  </MenuItem>
                </Menu>

                <Button
                  color="inherit"
                  onClick={(event) => handleMenuToggle(setAcademicAnchorEl, event)}
                  sx={buttonStyles}
                  endIcon={getDropdownIcon(academicAnchorEl)}
                >
                  Academic Affairs
                </Button>
                <Menu
                  anchorEl={academicAnchorEl}
                  open={Boolean(academicAnchorEl)}
                  onClose={() => setAcademicAnchorEl(null)}
                >
                  <MenuItem
                    component={Link}
                    to="/academic-affairs/academic-details"
                    onClick={() => setAcademicAnchorEl(null)}
                  >
                    Academic Details
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/academic-affairs/exam-schedule"
                    onClick={() => setAcademicAnchorEl(null)}
                  >
                    Exam Schedule
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/academic-affairs/admission"
                    onClick={() => setAcademicAnchorEl(null)}
                  >
                    Admission
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/academic-affairs/hostel"
                    onClick={() => setAcademicAnchorEl(null)}
                  >
                    Hostel Facilities
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/academic-affairs/library"
                    onClick={() => setAcademicAnchorEl(null)}
                  >
                    Library
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/academic-affairs/students"
                    onClick={() => setAcademicAnchorEl(null)}
                  >
                    Students
                  </MenuItem>
                </Menu>

                <Button
                  color="inherit"
                  onClick={(event) => handleMenuToggle(setDepartmentAnchorEl, event)}
                  sx={buttonStyles}
                  endIcon={getDropdownIcon(departmentAnchorEl)}
                >
                  Departments
                </Button>
                <Menu
                  anchorEl={departmentAnchorEl}
                  open={Boolean(departmentAnchorEl)}
                  onClose={() => setDepartmentAnchorEl(null)}
                >
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'ORAL_MEDICINE'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Oral Medicine & Radiology
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'ORAL_SURGERY'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Oral & Maxillofacial Surgery
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'PEDODONTICS'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Pedodontics & Preventive Dentistry
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'ORTHODONTICS'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Orthodontics & Dentofacial Orthopedics
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'PERIODONTOLOGY'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Periodontology
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'CONSERVATIVE'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Conservative Dentistry & Endodontics
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'PROSTHODONTICS'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Prosthodontics and Crown & Bridge
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'PUBLIC_HEALTH'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Public Health Dentistry
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'PATHOLOGY'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Oral Pathology & Microbiology
                  </MenuItem>

                  <MenuItem
                    component={Link}
                    to={`/departments/overview/${'IMPLANTOLOGY'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    Implantology
                  </MenuItem>

                  {/* <MenuItem
                    component={Link}
                    to={`/departments/overview/${'CAD-CAM'}`}
                    onClick={() => setDepartmentAnchorEl(null)}
                  >
                    CAD-CAM Dentistry
                  </MenuItem> */}
                </Menu>

                {isExpanded && (
                  <>
                    <Button component={Link} to="/syllabus" color="inherit" sx={buttonStyles}>
                      Syllabus
                    </Button>
                    {/* <Button component={Link} to="/vision" color="inherit" sx={buttonStyles}>
                      Vision and Mission
                    </Button> */}
                    <Button component={Link} to="/news" color="inherit" sx={buttonStyles}>
                      News and Events
                    </Button>
                    <Button
                      color="inherit"
                      onClick={(event) => handleMenuToggle(setGalleryAnchorEl, event)}
                      sx={buttonStyles}
                      endIcon={getDropdownIcon(galleryAnchorEl)}
                    >
                      Gallery
                    </Button>
                    <Menu
                      anchorEl={galleryAnchorEl}
                      open={Boolean(galleryAnchorEl)}
                      onClose={() => setGalleryAnchorEl(null)}
                    >
                      {/* <MenuItem
                        component={Link}
                        to="/gallery/image"
                        onClick={() => setGalleryAnchorEl(null)}
                      >
                        Image Gallery
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to="/gallery/video"
                        onClick={() => setGalleryAnchorEl(null)}
                      >
                        Video Gallery
                      </MenuItem> */}
                    </Menu>

                    <Button onClick={() => openPDF(nirf)} sx={buttonStyles}>
                      NIRF
                    </Button>
                    <Button
                      component={Link}
                      to="/alumni"
                      color="inherit"
                      sx={buttonStyles}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Alumni
                    </Button>
                    <Button
                      component={Link}
                      to="/iqac"
                      color="inherit"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={buttonStyles}
                    >
                      IQAC
                    </Button>

                    <Button component={Link} to="/contact" color="inherit" sx={buttonStyles}>
                      Contact Us
                    </Button>
                  </>
                )}
              </div>
            </Toolbar>
          )}
        </Toolbar>
      </AppBar>

      {/* Additional AppBar on top */}
      <AppBar
        position="fixed"
        elevation={0}
        style={{
          background: 'whitesmoke',
          width: '100%',
          top: 0,
          zIndex: 2,
          opacity: isScrolled ? 0 : 1,
          transition: 'opacity 1.0s ease-in-out'
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }} style={{ justifyContent: 'center' }}>
          <Typography variant="h6" style={{ color: textColor, textAlign: 'center' }}>
            Fired By The Zeal To Create World Class Institutions
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default ScrollNavbar;
