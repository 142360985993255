import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMediaQuery, styled } from '@mui/material';
import Boyshostel from './Boyshostel';
import Girlshostel from './Girlshostel';
import AHeader from '../components/AHeader';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: '#0180A6'
  },
  '.MuiTab-textColorInherit': {
    color: '#0180A6' 
  }
}));

const HostelPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: 'Boys Hostel',
        content: '<p>Boys hostel Content</p>',
        heading: 'bjhb',
        boyshostel: {
          heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
        }
      },
      {
        id: 1,
        label: 'Girls Hostel',
        content: '<p>Girls hostel Content</p>',
        girlshostel: {
          heading: 'admissions'
        }
      }
    ]
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AHeader header={'HOSTEL'} />
      <div
        style={{
          padding: isMobile ? '5px' : '30px',
          marginLeft: isMobile ? '5px' : '100px',
          marginRight: isMobile ? '5px' : '100px',

          marginBottom: isMobile ? '30px' : '40px'
        }}
      >
       
        {isMobile ? (
          <div>
            <CustomTabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="darkorange"
              orientation="vertical"
            >
              {tabsData.tabs.map((tab) => (
                <Tab key={tab.id} label={tab.label} value={tab.id} />
              ))}
            </CustomTabs>
            <div>
              {tabsData.tabs.map(
                (tab) =>
                  activeTab === tab.id && (
                    <div key={tab.id} style={{ marginLeft: '10px', marginTop: '10px' }}>
                      {tab.boyshostel ? <Boyshostel boyshostelData={tab.boyshostel} /> : null}
                      {tab.girlshostel ? <Girlshostel girlshostelData={tab.girlshostel} /> : null}
                    </div>
                  )
              )}
            </div>
          </div>
        ) : (
          <>
            <Card style={{ margin: '20px', padding: '20px' }}>
              <CustomTabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="black"
                textColor="red"
              >
                {tabsData.tabs.map((tab) => (
                  <Tab key={tab.id} label={tab.label} value={tab.id} />
                ))}
              </CustomTabs>
              <div>
                {tabsData.tabs.map(
                  (tab) =>
                    activeTab === tab.id && (
                      <div key={tab.id} style={{ marginLeft: '10px', marginTop: '10px' }}>
                        {tab.boyshostel ? <Boyshostel boyshostelData={tab.boyshostel} /> : null}
                        {tab.girlshostel ? <Girlshostel girlshostelData={tab.girlshostel} /> : null}
                      </div>
                    )
                )}
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default HostelPage;
