import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import loadingGif from 'assets/BDCH_GIF.gif';

function Loader({ loading }) {
  if (loading) {
    // return (
    //   <div
    //     style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    //   >
    //     <img src={loadingGif} alt="Loading..." />
    //   </div>
    // );
    return (
      <Dialog open={loading} fullScreen>
        <DialogContent sx={{ backgroundColor: '#112D58' }}>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <img src={loadingGif} alt="Loading..." />
          </Box>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

export default Loader;
