import { Grid, Typography } from '@mui/material';
import { viewNews } from 'api-ws/news-ws';
import React, { useEffect, useState } from 'react';
import { Marquee } from '@devnomic/marquee';
import '@devnomic/marquee/dist/index.css';
import { viewAlumniNews } from 'api-ws/alumniNews-ws';
import { useNavigate } from 'react-router-dom';
import newgif from './newgif.gif';

function HorizontalNews({ colorConfig, alumninews, setIsLoading }) {
  const { textColor } = colorConfig;
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Get navigate function
  const fetchNews = () => {
    setIsLoading(true);
    if (alumninews) {
      setLoading(true);
      viewAlumniNews()
        .then((res) => {
          setLoading(false);
          setIsLoading(false);
          // setNews(res.data.alumniNews);
          const sortedNews = res.data.alumniNews.sort((a, b) =>
            a.priority === b.priority ? 0 : a.priority ? -1 : 1
          );

          setNews(sortedNews);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      viewNews()
        .then((res) => {
          setLoading(false);
          setIsLoading(false);
          const sortedNews = res.data.data.sort((a, b) =>
            a.priority === b.priority ? 0 : a.priority ? -1 : 1
          );

          setNews(sortedNews);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    fetchNews();
  }, [alumninews]);

  const handleViewMoreClick = () => {
    if (alumninews) {
      navigate('/alumni/news');
    } else {
      navigate('/news');
    }
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: alumninews ? '' : textColor,
        width: '100%',
        height: '10vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {/* <Loader loading={loading} /> */}
      <Marquee fade={true} direction="left" gradient={false} pauseOnHover={true}>
        {news.map((item, index) => (
          <>
            {item?.priority ? (
              <img
                src={newgif}
                alt="New Gif"
                width="40"
                height="20"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
                style={{ marginLeft: '1rem' }}
              ></img>
            ) : null}
            <Typography
              key={index}
              variant="h6"
              style={{
                color: alumninews ? '' : 'whitesmoke',
                marginRight: '4vh',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {item?.title}
              <span
                style={{
                  cursor: 'pointer',
                  marginLeft: '8px',
                  marginRight: '8px',
                  fontSize: '15px'
                }}
                onClick={handleViewMoreClick}
              >
                ...View more
              </span>
            </Typography>
          </>
        ))}
      </Marquee>
    </Grid>
  );
}

export default HorizontalNews;
