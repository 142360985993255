import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addAlumniContact = (contactRequest, authToken) => {
  const urlPath = `/alumnicontact/addContacts`;
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: contactRequest
  });
};

export const viewAlumniContact = (authToken) => {
  const urlPath = `/alumnicontact/getAllContacts`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};

export const removeContact = (id, authToken) => {
  const urlPath = `/alumnicontact/removeContacts/${id}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};
