import { Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import React from 'react';

function AHeader({ header }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: isMobile ? '10vh' : '28vh',
          marginBottom: '15px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="h4">{header}</Typography>
        <Divider
          sx={{
            width: '80vw',
            marginTop: '30px',
            marginBottom: '20px',
            backgroundColor: 'black',
            height: '1px'
          }}
        />
      </Grid>
    </div>
  );
}

export default AHeader;
