import React from 'react';
import {
  useMediaQuery,
  Grid,
  Typography,
  useTheme,
  Divider,
  CardContent,
  Card,
  CardActionArea
} from '@mui/material';
import { Hourglass } from 'react-loader-spinner';
import ImageViewer from 'react-simple-image-viewer';
import DepButtons from './DepButtons';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';

function DepartmentGallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const department = departmentData[id];

  const galleryData = department?.gallery;

  const srcArray = galleryData?.map((item) => item.src);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  //   useEffect(() => {
  //     setLoading(true);
  //   }, [galleryData]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!department) {
    return <div style={{ marginTop: '40vh' }}>Department not found</div>;
  }

  return (
    <Grid container style={{ marginTop: isMobile ? '8vh' : '28vh' }}>
      {isMobile ? <DepNameBar id={id} department={department}></DepNameBar> : null}

      {isMobile ? null : (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            {department?.name} GALLERY
          </Typography>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: 'black',
              height: '2px',
              width: '90%',
              margin: { xs: '10px auto', sm: '10px auto' }
            }}
          />
        </Grid>
      )}

      <Grid container style={{ paddingTop: '10px', justifyContent: 'center' }}>
        {/* DepButtons on the left */}
        {isMobile ? null : (
          <Grid item xs={12} md={2} style={{ paddingRight: '20px' }}>
            <DepButtons id={id} />
          </Grid>
        )}

        {/* Image and dept1.overview in the middle */}
        <Grid item xs={12} md={8}>
          <div>
            {loading && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 999
                }}
              >
                <Hourglass
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={['#97905E', '#000000']}
                />
              </div>
            )}
            <Grid
              container
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignContent: 'center'
              }}
            >
              <Grid container spacing={2}>
                {galleryData?.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index} justifyContent="center">
                    <Card
                      sx={{
                        maxWidth: 320,
                        margin: isMobile ? '30px' : '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
                        }
                      }}
                    >
                      <CardActionArea
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'stretch',
                          height: '100%',
                          width: '100%',
                          padding: 0,
                          margin: 0
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '0',
                            paddingBottom: '75%',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: 0
                          }}
                        >
                          <img
                            src={image.src}
                            onClick={() => openImageViewer(index)}
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                            alt={image.alt}
                          />
                        </div>
                        <Typography
                          sx={{
                            marginTop: '8px',
                            textAlign: 'center',
                            padding: '0 8px',
                            flexGrow: 1,
                            margin: 0
                          }}
                        >
                          {image.alt}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {isViewerOpen && (
                <ImageViewer
                  src={srcArray}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DepartmentGallery;
