import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

function Iqacfooter() {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div
      style={{
        backgroundColor: '#080808',
        minHeight: '10vh',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row', // Stack items vertically on small screens
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        marginTop: '300px',
        textAlign: isMobile ? 'center' : 'left' // Center text on small screens
      }}
    >
      <div style={{ fontSize: '14px', color: 'white', marginBottom: isMobile ? '10px' : '0' }}>
        Copyright 2017 Bapuji Dental College, All Right Reserved
      </div>
      <div style={{ fontSize: '14px', color: 'white' }}>
        <a
          href="https://bytecrafts.in"
          style={{ color: 'white', textDecoration: 'underline' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Designed & Developed by Bytecrafts
        </a>
      </div>
    </div>
  );
}

export default Iqacfooter;
