import ANews from './alumni/ANews';
import AlumniContact from './alumni/AlumniContact';
import AlumniGallery from './alumni/alumniGallery/AlumniGallery';
import Directory from './alumni/Directory';
import AlumniHome from './alumni/Home/AlumniHome';
import Membership from './alumni/Membership';
import Events from './alumni/alumniGallery/AlumniGalleryEvent';

const alumniRoutes = [
  // Alumni
  { path: '/alumni/', element: <AlumniHome /> },

  { path: '/alumni/directory', element: <Directory /> },
  { path: '/alumni/membership', element: <Membership /> },
  { path: '/alumni/news', element: <ANews /> },
  { path: '/alumni/gallery', element: <AlumniGallery /> },
  { path: '/alumni/gallery/:id', element: <Events /> },
  { path: '/alumni/contact', element: <AlumniContact /> }
];

export default alumniRoutes;
