import { Typography, Card, Link } from '@mui/material';
import React from 'react';

const Mission = ({ missionData }) => {
  return (
    <div>
      {/* {procedureData.heading} */}
      <ol style={{ fontSize: '22px' }}>
        <li>
          <Typography style={{ fontSize: '20px' }}>Learner Centered Dental Education</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Patient Centered Service</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Community Oriented Research</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Strong Community Relationship </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Serve The Under Served</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Meet the Regional, National And</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Global Dental Educational Needs</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Inter Organizational Needs</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Strategic Future Oriented Planning</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Excellence In Knowledge, Skills And Service
          </Typography>
        </li>
      </ol>
    </div>
  );
};

export default Mission;
