import React from 'react';
import { useState } from 'react';

// import pdf from './123.pdf';
import { useEffect } from 'react';
function Prospectus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = () => {
    setNumPages(numPages);
  };
  return (
    <div style={{ marginTop: '200px' }}>
      <div>{/* <iframe src={pdf} width="100%" height="500px" /> */}</div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.5442066055552!2d75.91392931432863!3d14.453411184542869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba2571eb98f1fd%3A0x1e9b7635c879caac!2sBapuji+Dental+College+and+Hospital!5e0!3m2!1sen!2sin!4v1506588013103"
        width="100%"
        height="350"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </div>
  );
}

export default Prospectus;
