// import React, { useState } from 'react';
// import { Grid, Typography, useMediaQuery } from '@mui/material';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import carouselData from './departmentData.json';

// const CarosuelPage = (carouselData) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const isMobile = useMediaQuery('(max-width: 600px)');

//   return (
//     <Grid>
//       <Grid style={{ display: 'flex', justifyContent: 'center' }}>
//         <Carousel
//           showArrows={true}
//           showStatus={false}
//           showThumbs={false}
//           infiniteLoop={true}
//           selectedItem={currentIndex}
//           autoPlay={true}
//           interval={5000}
//           width={'100%'}
//           onChange={(index) => setCurrentIndex(index)} // Update currentIndex on slide change
//         >
//           {carouselData.map((carouselItem, index) => (
//             <div key={index} style={{ position: 'relative' }}>
//               {' '}
//               {/* Changed to div */}
//               <img
//                 src={carouselItem.imageUrl}
//                 alt={carouselItem.heading}
//                 style={{
//                   objectFit: isMobile ? 'contain' : 'cover',
//                   maxHeight: '100%',
//                   maxWidth: '100%',
//                   height: isMobile ? '300px' : '700px'
//                 }}
//               />
//               <div
//                 style={{
//                   position: 'absolute',
//                   top: 0,
//                   left: 0,
//                   width: '100%',
//                   textAlign: 'center',
//                   color: 'white',
//                   zIndex: 1,
//                   backgroundColor: 'rgba(0, 0, 0, 0.4)',
//                   background: 'linear-gradient(rgba(0,0,0,0,1)0%,rgba(119,76,0,1)100%)',
//                   padding: '20px'
//                 }}
//               >
//                 <Typography
//                   variant="h2"
//                   style={{
//                     fontFamily: 'Caroline',
//                     color: 'white',
//                     fontWeight: 'bold',
//                     fontSize: isMobile ? '2rem' : '4rem',
//                     marginLeft: '10px',
//                     textAlign: 'center'
//                   }}
//                 >
//                   {carouselItem.heading}
//                 </Typography>
//               </div>
//               {carouselItem.content && (
//                 <div
//                   style={{
//                     position: 'absolute',
//                     bottom: 0,
//                     left: 0,
//                     width: '100%',
//                     textAlign: 'left',
//                     color: 'white',
//                     zIndex: 1,
//                     padding: '20px'
//                   }}
//                 >
//                   <Typography
//                     style={{
//                       fontSize: isMobile ? '0.5rem' : '1.5rem',
//                       marginBottom: '10px'
//                     }}
//                   >
//                     {Array.isArray(carouselItem.content)
//                       ? carouselItem.content.map((line, idx) => (
//                           <div key={idx} style={{ marginBottom: '5px' }}>
//                             {line}
//                           </div>
//                         ))
//                       : carouselItem.content}
//                   </Typography>
//                 </div>
//               )}
//             </div>
//           ))}
//         </Carousel>
//       </Grid>
//     </Grid>
//   );
// };

// export default CarosuelPage;
// import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

// const CarosuelPage = ({ carouselData }) => {
//   return (
//     <Carousel
//       autoPlay={true}
//       infiniteLoop={true}
//       showArrows={true}
//       showThumbs={false}
//       showStatus={false}
//       interval={3000}
//       transitionTime={500}
//     >
//       {carouselData.map((item, index) => (
//         <div key={index}>
//           <img src={item.imageUrl} alt={item.heading} />
//           <div className="legend">
//             <h1>{item.heading}</h1>
//           </div>
//         </div>
//       ))}
//     </Carousel>
//   );
// };

// export default CarosuelPage;
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CarouselPage = ({ carouselData }) => {
  const styles = {
    carouselItem: {
      position: 'relative'
    },
    image: {
      width: '100%',
      height: 'auto' // Ensures images maintain their aspect ratio
    },
    legend: {
      position: 'absolute',
      top: '20px', // Adjust as needed
      left: '50%',
      transform: 'translateX(-50%)',
      color: 'white', // Adjust text color as needed
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', // Shadow for better visibility
      textAlign: 'center' // Center the text
    },
    heading: {
      fontSize: '2.5rem', // Increase font size
      margin: 0 // Remove default margin
    }
  };

  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      showArrows={true}
      showThumbs={false}
      showStatus={false}
      interval={3000}
      transitionTime={500}
    >
      {carouselData.map((item, index) => (
        <div key={index} style={styles.carouselItem}>
          <img src={item.imageUrl} alt={item.heading} style={styles.image} />
          <div style={styles.legend}>
            <h1 style={styles.heading}>{item.heading}</h1>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselPage;
