import React, { useState, useEffect } from 'react';
import { Card, Container, Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMediaQuery, styled } from '@mui/material';
import Procedure from './Procedure';
import Forms from './Forms';
import Keycontact from './Keycontact';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: '#0180A6'
  },
  '.MuiTab-textColorInherit': {
    color: '#2360BD'
  }
}));

const AdmissionPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabsData = [
    {
      id: 0,
      label: 'Application Procedures',
      procedure: {
        heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
      }
    },
    {
      id: 1,
      label: 'Application Form',
      forms: {
        heading: 'af'
      }
    },
    {
      id: 2,
      label: 'Key Contact',
      keycontact: {
        heading: 'kc'
      }
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <AHeader header={'ADMISSIONS'} />
      <div style={{ padding: isMobile ? '0px' : '15px', marginLeft: '3px', marginRight: '5px' }}>
        <Grid container spacing={5}>
          {isMobile ? (
            <>
              <Grid item xs={12} md={8} sm={4}>
                <Card
                  style={{ margin: '5px', padding: isMobile ? '0px' : '10px', maxWidth: '100%' }}
                >
                  <CustomTabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="darkorange"
                    orientation="vertical"
                  >
                    {tabsData.map((tab) => (
                      <Tab key={tab.id} label={tab.label} value={tab.id} />
                    ))}
                  </CustomTabs>
                  <div style={{ marginLeft: '5px', marginTop: '10px' }}>
                    {tabsData.map(
                      (tab) =>
                        activeTab === tab.id && (
                          <div key={tab.id}>
                            {tab.procedure && <Procedure procedureData={tab.procedure} />}
                            {tab.forms && <Forms formsData={tab.forms} />}
                            {tab.keycontact && <Keycontact keycontactData={tab.keycontact} />}
                          </div>
                        )
                    )}
                  </div>
                </Card>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={9}>
                <Card style={{ margin: '5px', padding: isMobile ? '5px' : '5px' }}>
                  <CustomTabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="darkorange"
                  >
                    {tabsData.map((tab) => (
                      <Tab key={tab.id} label={tab.label} value={tab.id} />
                    ))}
                  </CustomTabs>
                  <div style={{ marginLeft: '5px', marginTop: '10px', maxWidth: '100%' }}>
                    {tabsData.map(
                      (tab) =>
                        activeTab === tab.id && (
                          <div key={tab.id}>
                            {tab.procedure && <Procedure procedureData={tab.procedure} />}
                            {tab.forms && <Forms formsData={tab.forms} />}
                            {tab.keycontact && <Keycontact keycontactData={tab.keycontact} />}
                          </div>
                        )
                    )}
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <VerticalNewsBanner />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Container>
  );
};

export default AdmissionPage;
