import { useTheme } from '@emotion/react';
import { Avatar, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

function PrincipalMessage({ colorConfig }) {
  const { textColor, bigtext, smalltext } = colorConfig;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container xs={12} style={{ paddingLeft: '20px', marginTop: '4vh', marginBottom: '5vh' }}>
      <Grid item xs={12}>
        <Typography style={{ color: bigtext, fontWeight: '4vh', fontSize: '25px' }}>
          <b>Message From The Principal</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ paddingTop: '10px', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
      >
        <Grid item xs={12} md={8} sx={{ order: { xs: 2, md: 1 }, textAlign: 'center' }}>
          <Typography
            style={{
              textAlign: 'justify',
              fontSize: '20px',
              color: smalltext,
              paddingRight: isMobile ? '15px' : 0
            }}
          >
            Choosing Bapuji Dental College & Hospital (BDCH) means embarking on a transformative
            journey guided by Our esteemed and experienced faculty. Our commitment extends beyond
            academic excellence to fostering holistic personality development. We strive to help you
            unlock your fullest potential by immersing you in a rich reservoir of knowledge and
            experience. Our goal is to ensure that upon graduation, you are not only academically
            accomplished but also well-prepared for a fulfilling and successful life.
          </Typography>
          <Typography
            style={{
              textAlign: 'justify',
              fontSize: '20px',
              marginTop: '10px',
              color: smalltext,
              paddingRight: isMobile ? '15px' : 0
            }}
          >
            BDCH has earned a distinguished National and International reputation over more than 40
            years of dedicated service and excellence. Our outstanding student pass rates are a
            testament to our high standards, further highlighted by the numerous national awards our
            students have achieved. Many of Our Alumni have risen to prominent positions in
            Prestigious Colleges and Hospitals Worldwide, continuing to uphold the values and
            excellence that BDCH represents.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            order: { xs: 1, md: 2 },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar
              alt="Remy Sharp"
              src="/assets/principal/principal.jpg"
              sx={{ width: 230, height: 230 }}
            />
          </Grid>
          <Grid
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '15px'
            }}
          >
            <Typography style={{ color: textColor, fontSize: '20px', fontWeight: '4vh' }}>
              <b style={{ margin: 0 }}>Dr. D.B. Nandeeshwar</b>
              <br />
              <p style={{ margin: '0', textAlign: 'center' }}>Principal</p>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PrincipalMessage;
