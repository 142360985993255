import React from 'react';
import {
  useMediaQuery,
  Grid,
  Typography,
  useTheme,
  Divider,
  styled,
  Card,
  Tabs,
  Tab,
  Button
} from '@mui/material';
import DepButtons from './DepButtons';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import colorConfig2 from 'app/color/colorConfig2';

const { navbarColor, bigtext } = colorConfig2;

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: navbarColor
  },
  '.MuiTab-textColorInherit': {
    color: navbarColor
  }
}));

function DepartmentSyllabus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [activeTab, setActiveTab] = useState(0);
  const { id } = useParams();
  //   console.log('overview', id);
  const department = departmentData[id].syllabus;
  const department_bds = departmentData[id].syllabus.bds;
  const department_mds = departmentData[id].syllabus.mds;
  console.log('d', department_bds);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: 'BDS syllabus',
        content: '<p>BDS Content</p>',
        bdssyllabus: {
          heading: 'BDS syllabus'
        }
      },
      {
        id: 1,
        label: 'MDS syllabus',
        content: '<p>MDS Content</p>',
        mdssyllabus: {
          heading: 'MDS syllabus'
        }
      }
    ]
  };

  if (!department) {
    return <div style={{ marginTop: '40vh' }}>Department not found</div>;
  }
  return (
    <Grid container style={{ marginTop: isMobile ? '8vh' : '28vh' }}>
      {isMobile ? <DepNameBar id={id} department={department}></DepNameBar> : null}

      {isMobile ? null : (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            {department?.name} Syllabus
          </Typography>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: 'black',
              height: '2px',
              width: '90%',
              margin: { xs: '10px auto', sm: '10px auto' }
            }}
          />
        </Grid>
      )}

      <Grid container style={{ paddingTop: '10px' }}>
        {/* DepButtons on the left */}
        {isMobile ? null : (
          <Grid item xs={12} md={2} style={{ paddingRight: '20px' }}>
            <DepButtons id={id} />
          </Grid>
        )}

        {/* Image and dept1.overview in the middle */}
        <Grid item xs={12} md={10}>
          <>
            <Card style={{ marginLeft: '10px', marginRight: '10px' }}>
              <CustomTabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="darkorange"
              >
                {tabsData.tabs.map((tab) => (
                  <Tab key={tab.id} label={tab.label} value={tab.id} />
                ))}
              </CustomTabs>
              <div>
                {tabsData.tabs.map(
                  (tab) =>
                    activeTab === tab.id && (
                      <div key={tab.id} style={{ marginLeft: '10px', marginTop: '10px' }}>
                        {tab.bdssyllabus &&
                          (isMobile ? (
                            <Button onClick={() => openPDF(department_bds)}>
                              {' '}
                              Publictions? Please Click here
                            </Button>
                          ) : (
                            <Grid
                              xs={12}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <iframe
                                src={department_bds}
                                title="Publications"
                                width="80%"
                                height="1000px"
                              />
                            </Grid>
                          ))}
                        {tab.mdssyllabus &&
                          (isMobile ? (
                            <Button onClick={() => openPDF(department_mds)}>
                              {' '}
                              Syllabus? Please Click here
                            </Button>
                          ) : (
                            <Grid
                              xs={12}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <iframe
                                src={department_mds}
                                title="Publications"
                                width="80%"
                                height="1000px"
                              />
                            </Grid>
                          ))}
                      </div>
                    )
                )}
              </div>
            </Card>
          </>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DepartmentSyllabus;
