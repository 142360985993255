import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addMemberShip = (memberShipRequest) => {
  const urlPath = `/alumnimembership/addMembership`;
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: memberShipRequest
  });
};

export const updateMemberShip = (memberShipRequest, id) => {
  const urlPath = `/alumnimembership/updateMembership/${id}`;
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: memberShipRequest
  });
};

export const getMemberShipDeails = (authToken) => {
  const urlPath = `/alumnimembership/getAllMembershipDetails`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};

export const removeMemberShipDetails = (id, authToken) => {
  const urlPath = `/alumnimembership/removeMembership/${id}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};
