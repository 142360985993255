import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import logo from '../components/baplogo.png';

export default function Footer() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid
        container
        component="footer"
        sx={{
          backgroundColor: '#0180A6',
          paddingBottom: '2vh',
          paddingTop: '2vh',
          marginTop: '10vh'
        }}
      >
        <Grid
          container
          spacing={2}
          xs={12}
          style={{
            // marginLeft: isMobile ? '' : '10px',
            alignItems: 'start',
            marginTop: '10px',
            textAlign: 'start',
            justifySelf: 'center',
            justifyContent: 'start',
            marginLeft: '8%',
            marginRight: '20px'
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              paddingLeft: '20px',
              paddingRight: '20px'
            }}
          >
            <Typography color="whitesmoke" gutterBottom>
              <img src={logo} alt="Elite Logo" style={{ height: '50px' }} />
            </Typography>
            <Typography variant="body2" color="whitesmoke" textAlign="justify">
              45 Glorious Years of Excellence
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={2}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              textAlign: isMobile ? 'start' : 'start'
            }}
          >
            <Typography
              style={{ fontWeight: 'bold', fontSize: '20px' }}
              color="whitesmoke"
              gutterBottom
            >
              Quick Links
            </Typography>
            <Link to="/">
              <Typography color="whitesmoke" gutterBottom>
                Home
              </Typography>
            </Link>
            {/* <Typography color="whitesmoke" gutterBottom>
              <a href="https://www.google.com/maps/place/Bapuji+Dental+College+and+Hospital/@14.453406,75.9135431,17z/data=!3m1!4b1!4m6!3m5!1s0x3bba2571eb98f1fd:0x1e9b7635c879caac!8m2!3d14.453406!4d75.916118!16s%2Fg%2F1tgpwn32?entry=ttu">
                {' '}
                Site Map
              </a>
            </Typography> */}
            <Link to="/administration/management">
              <Typography color="whitesmoke" gutterBottom>
                Administration
              </Typography>
            </Link>
            {/* <Typography color="whitesmoke" gutterBottom>
              Department
            </Typography> */}
            <Link to="/academic-affairs/admission">
              <Typography color="whitesmoke" gutterBottom>
                Admission
              </Typography>
            </Link>

            <Link to="/news">
              <Typography color="whitesmoke" gutterBottom>
                News and Events
              </Typography>
            </Link>
          </Grid>
          {/* contact */}
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: isMobile ? 'start' : 'left'
            }}
          >
            <Typography variant="h6" color="whitesmoke" gutterBottom>
              Contact Us
            </Typography>

            <Typography color="whitesmoke" gutterBottom>
              Principal : 08192220572
            </Typography>
            <Typography color="whitesmoke" gutterBottom>
              Vice Principal: 08192220575
            </Typography>
            <Typography color="whitesmoke" gutterBottom>
              Manager: 08192220573
            </Typography>
            <Typography color="whitesmoke" gutterBottom>
              Email:- principal@bapujidental.edu
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            style={{
              display: 'flex',
              maxWidth: '180px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: isMobile ? 'start' : 'center'
            }}
          >
            <Typography variant="h6" color="whitesmoke" gutterBottom>
              Follow Us
            </Typography>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Typography color="whitesmoke" gutterBottom>
                <a
                  href="https://www.facebook.com/profile.php?id=100064657090851"
                  aria-label="Visit Facebook Profile"
                >
                  <Facebook style={{ color: 'whitesmoke' }} />
                </a>
              </Typography>

              <Typography color="whitesmoke" gutterBottom style={{ marginLeft: '10px' }}>
                <a href="https://www.instagram.com/" aria-label="Visit Instagram Profile">
                  <Instagram style={{ color: 'whitesmoke' }} />
                </a>
              </Typography>

              <Typography color="whitesmoke" gutterBottom style={{ marginLeft: '10px' }}>
                <a href="https://www.twitter.com/" aria-label="Visit twitter Profile">
                  <Twitter style={{ color: 'whitesmoke' }} />
                </a>
              </Typography>

              <Typography color="whitesmoke" gutterBottom style={{ marginLeft: '10px' }}>
                <a
                  href="https://www.youtube.com/user/bapujidentalcollege"
                  aria-label="Visit Youtube"
                >
                  <YouTube style={{ color: 'whitesmoke' }} />
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          spacing={2}
          xs={12}
          style={{
            marginTop: '30px',
            paddingLeft: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography color="whitesmoke" gutterBottom>
              All Rights Reserved Bapuji Dental College & Hospital
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography color="whitesmoke" gutterBottom>
              {' '}
              <a href="http://bytecrafts.in">Designed and Developed by ByteCrafts</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
