import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import ListItemIcon from '@mui/material/ListItemIcon';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { viewNews } from 'api-ws/news-ws';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import AHeader from '../components/AHeader';
import BDCH_GIF from 'assets/BDCH_GIF.gif';

function News() {
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchNews = () => {
    setLoading(true);
    viewNews()
      .then((res) => {
        console.log('membershipDetails', res.data.data);
        setNews(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  return (
    <Grid style={{ marginTop: isMobile ? '8vh' : '25vh' }}>
      <AHeader header={'NEWS & EVENTS'} />

      <Grid style={{ height: 'auto', marginLeft: '40px', paddingRight: isMobile ? '40px' : 0 }}>
        <List sx={{ width: '100%' }}>
          {news?.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: '10px' }}>
                        {item?.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ marginBottom: '10px' }}
                        overflow="auto"
                      >
                        {item?.description}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        display="flex"
                        alignItems="center"
                      >
                        <EventIcon />
                        <span style={{ marginRight: '6vh', marginLeft: '1vh' }}>
                          {moment(item?.createdDate).format('DD MMM YYYY')}
                        </span>

                        <PersonIcon />

                        <span style={{ marginLeft: '1vh', marginRight: '6vh' }}>
                          {item?.newsaddedby}
                        </span>

                        {item?.pdfFiles && (
                          <Button
                            onClick={() => openPDF(item?.pdfFiles)}
                            style={{ color: '#808080' }}
                          >
                            <PictureAsPdfIcon />
                            <span style={{ marginLeft: '1vh', marginRight: '6vh' }}>
                              Click Here
                            </span>
                          </Button>
                        )}

                        {/* <PictureAsPdfIcon />
                        <span style={{ marginLeft: '1vh', marginRight: '6vh' }}>Click Here</span> */}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              {index < news.length - 1 && (
                <Divider
                  sx={{
                    width: '80vw',
                    height: '0.5px'
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default News;
