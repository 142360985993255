import React from 'react';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import Fab from '@mui/material/Fab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ResponsiveCard from './ResponsiveCard';
import data from './data.json';
import { Grid } from '@mui/material';

export default function ResponsiveCarousel({ colorConfig }) {
  const { textColor } = colorConfig;
  const ref = React.useRef();

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          const currentVisibleSlide = parentWidth <= 1440 ? 3 : 5;
          const slideWidth = parentWidth < 800 ? parentWidth - 60 : 500;

          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={(props) => <Card {...props} colorConfig={colorConfig} />}
              slideWidth={slideWidth}
              carouselWidth={parentWidth}
              data={data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
            />
          );
        }}
      />
      <>
        <Fab
          style={{
            position: 'absolute',
            top: '40%',
            left: 10,
            zIndex: 10,
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
          size="small"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <ArrowBackIosIcon style={{ color: textColor }} />
        </Fab>
        <Fab
          style={{
            position: 'absolute',
            top: '40%',
            right: 10,
            zIndex: 10,
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
          size="small"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <ArrowForwardIosIcon style={{ color: textColor }} />
        </Fab>
      </>
    </div>
  );
}

export const Card = React.memo(function (props) {
  const { data, dataIndex, colorConfig } = props;
  const { cover, title, description } = data[dataIndex];

  return (
    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ResponsiveCard
        title={title}
        cover={cover}
        description={description}
        colorConfig={colorConfig}
      />
    </Grid>
  );
});
