import React, { useEffect, useState } from 'react';
import { Card, Grid, CardContent, Button, Typography, styled, Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { addAlumniContact } from 'api-ws/alumniContact-ws';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

// Custom styled component for TextField
const TextField = styled(TextValidator)(({ theme }) => ({
  width: '100%',
  marginBottom: '16px'
}));

function AlumniContact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const addContact = () => {
    const contact = {
      name: name,
      email: email,
      message: message
    };
    addAlumniContact(contact)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log('error', e));
  };

  return (
    <Grid container direction="column">
      <Box sx={{ margin: '20px' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.5442066055552!2d75.91392931432863!3d14.453411184542869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba2571eb98f1fd%3A0x1e9b7635c879caac!2sBapuji+Dental+College+and+Hospital!5e0!3m2!1sen!2sin!4v1506588013103"
          title="Maps Location"
          width="100%"
          height="350"
          frameBorder="0"
          allowFullScreen=""
        ></iframe>
      </Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: { xs: 'auto', md: '100vh' } }}
        spacing={2}
      >
        <Grid item xs={12} md={10}>
          <Card
            sx={{
              padding: 3,
              background: '#feffff',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Card
              sx={{
                width: { xs: '100%', md: '40%' },
                padding: 2,
                margin: 'auto',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: '10px'
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 28 }}>Address:</Typography>
              <CardContent>
                <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
                  DR. H. S. Shashidhara
                </Typography>
                <Typography sx={{ fontSize: 18 }}>Hon. Secretary, BEAUIDEAL</Typography>
                <Typography sx={{ fontSize: 18 }}>Professor Dept. of Prosthodontics</Typography>
                <Typography sx={{ fontSize: 18 }}>College of Dental Sciences</Typography>
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  <MailOutlineIcon style={{ marginRight: '5px', fontSize: '15px' }} />
                  hebbalshashi@gmail.com
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                width: { xs: '100%', md: '40%' },
                padding: 2,
                margin: 'auto',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: '10px'
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Get in Touch</Typography>
              <CardContent>
                <ValidatorForm onSubmit={addContact} onError={() => null}>
                  <TextField
                    label="Name"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ background: 'lightgrey' }}
                    validators={['required']}
                    errorMessages={['this field is required']}
                  />
                  <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    sx={{ background: 'lightgrey' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                  />
                  <TextField
                    label="Message"
                    multiline
                    fullWidth
                    variant="outlined"
                    sx={{ background: 'lightgrey' }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: '#2360BD',
                      display: 'block',
                      margin: 'auto',
                      color: '#ffffff'
                    }}
                  >
                    Send Message
                  </Button>
                </ValidatorForm>
              </CardContent>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AlumniContact;
