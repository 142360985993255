const colorConfig = {
    backgroundColor: '#A3CEF1',
    textColor: '#274C77',
    // Add more colors as needed
    primaryColor: '#1976d2',
    secondaryColor: '#f50057',
    navbarColor: '#6096BA',
    smalltext : '#8B8C89',
    bigtext : '#000000',
    small: '#0C0C0C',
    big: '#274C77'
  };
  
  export default colorConfig;