import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Divider, Collapse } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function DrawerComponent() {
  const [open, setOpen] = React.useState(false);
  const [certOpen, setCertOpen] = React.useState(false);
  const [aqarOpen, setAqarOpen] = React.useState(false);
  const [ssrOpen, setSsrOpen] = React.useState(false);
  const [iqacOpen, setIqacOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleCertToggle = () => {
    setCertOpen(!certOpen);
  };

  const handleAqarToggle = () => {
    setAqarOpen(!aqarOpen);
  };

  const handleSsrToggle = () => {
    setSsrOpen(!ssrOpen);
  };

  const handleIqacToggle = () => {
    setIqacOpen(!iqacOpen);
  };

  // This function resets all collapse states when a link is clicked
  const resetCollapseStates = () => {
    setCertOpen(false);
    setAqarOpen(false);
    setSsrOpen(false);
    setIqacOpen(false);
  };

  const handleLinkClick = () => {
    handleDrawerToggle();
    resetCollapseStates();
  };

  return (
    <>
      <IconButton edge="start" color="inherit" onClick={handleDrawerToggle}>
        <MenuIcon />
      </IconButton>

      <Drawer anchor="right" open={open} onClose={handleDrawerToggle}>
        <List>
          <ListItem component={Link} to="/iqac" onClick={handleLinkClick}>
            <ListItemText primary="HOME" />
          </ListItem>

          <ListItem onClick={handleCertToggle}>
            <ListItemText primary="CERTIFICATES" />
            {certOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={certOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem component={Link} to="/iqac/certificate2011" onClick={handleLinkClick}>
                <ListItemText primary="Certificate Of Accreditation 2011" />
              </ListItem>
              <ListItem component={Link} to="/iqac/certificate2017" onClick={handleLinkClick}>
                <ListItemText primary="Certificate Of Accreditation 2017" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem onClick={handleAqarToggle}>
            <ListItemText primary="AQAR" />
            {aqarOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={aqarOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem component={Link} to="/iqac/aqar1112" onClick={handleLinkClick}>
                <ListItemText primary="2011-12" />
              </ListItem>
              <ListItem component={Link} to="/iqac/aqar1213" onClick={handleLinkClick}>
                <ListItemText primary="2012-13" />
              </ListItem>
              <ListItem component={Link} to="/iqac/aqar1314" onClick={handleLinkClick}>
                <ListItemText primary="2013-14" />
              </ListItem>
              <ListItem component={Link} to="/iqac/aqar1415" onClick={handleLinkClick}>
                <ListItemText primary="2014-15" />
              </ListItem>
              <ListItem component={Link} to="/iqac/aqar1516" onClick={handleLinkClick}>
                <ListItemText primary="2015-16" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem onClick={handleSsrToggle}>
            <ListItemText primary="SSR" />
            {ssrOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={ssrOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem component={Link} to="/iqac/ssr20102011" onClick={handleLinkClick}>
                <ListItemText primary="SSR 2010-2011" />
              </ListItem>
              <ListItem component={Link} to="/iqac/ssr20162017" onClick={handleLinkClick}>
                <ListItemText primary="SSR 2016-2017" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem onClick={handleIqacToggle}>
            <ListItemText primary="IQAC Members" />
            {iqacOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={iqacOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem component={Link} to="/iqac/iqac2011" onClick={handleLinkClick}>
                <ListItemText primary="Internal Quality Assurance Cell 2011" />
              </ListItem>
              <ListItem component={Link} to="/iqac/iqac2017" onClick={handleLinkClick}>
                <ListItemText primary="Internal Quality Assurance Cell 2017" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem component={Link} to="/iqac/naacgallery" onClick={handleLinkClick}>
            <ListItemText primary="NAAC PEER TEAM VISIT PHOTOS" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </>
  );
}

export default DrawerComponent;
