import AQAR from './IQAC/AQAR';
import Certificates from './IQAC/Certificates';
import IqacHome from './IQAC/IqacHome';
import Members from './IQAC/Members';
import NaacGallery from './IQAC/NaacGallery';
import SSR from './IQAC/SSR';
import AlumniHome from './alumni/Home/AlumniHome';

import Certificates2011 from './IQAC/Certificates2011';
import Certificates2017 from './IQAC/Certificates2017';
import Aqar1112 from './IQAC/Aqar11-12';
import Aqar1415 from './IQAC/Aqar14-15';
import Aqar1314 from './IQAC/Aqar13-14';
import Aqar1213 from './IQAC/Aqar12-13';
import Aqar1516 from './IQAC/Aqar15-16';
import Ssr201011 from './IQAC/Ssr2010-11';
import Ssr201617 from './IQAC/Ssr2016-17';
import Iqac2017 from './IQAC/Iqac2017';
import Iqac2011 from './IQAC/Iqac2011';
import NaacGalleryEvents from './IQAC/NaacGalleryEvents';
import NG2011 from './IQAC/NG2011';
import NG2017 from './IQAC/NG2017';

const iqacRoutes = [
  // Alumni
  { path: '/iqac/', element: <IqacHome /> },
  { path: '/iqac/certificate2017', element: <Certificates2017 /> },
  { path: '/iqac/certificate2011', element: <Certificates2011 /> },
  { path: '/iqac/aqar1112', element: <Aqar1112 /> },
  { path: '/iqac/aqar1213', element: <Aqar1213 /> },
  { path: '/iqac/aqar1314', element: <Aqar1314 /> },
  { path: '/iqac/aqar1415', element: <Aqar1415 /> },
  { path: '/iqac/aqar1516', element: <Aqar1516 /> },
  { path: '/iqac/ssr20102011', element: <Ssr201011 /> },
  { path: '/iqac/ssr20162017', element: <Ssr201617 /> },

  { path: '/iqac/iqac2011', element: <Iqac2011 /> },
  { path: '/iqac/iqac2017', element: <Iqac2017 /> },

  { path: '/iqac/members', element: <Members /> },
  { path: '/iqac/naacgallery', element: <NaacGallery /> },
  { path: `/iqac/naacgallery/events/:id`, element: <NaacGalleryEvents /> },

  { path: '/iqac/2017', element: <NG2017 /> }
];

export default iqacRoutes;
