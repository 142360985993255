import React from 'react';
import { Route, Routes } from 'react-router-dom';
import IqacHome from './IQAC/IqacHome';
import Certificates from './IQAC/Certificates';
import AQAR from './IQAC/AQAR';
import SSR from './IQAC/SSR';
import Members from './IQAC/Members';
import NaacGallery from './IQAC/NaacGallery';
import NaacGalleryEvents from './IQAC/NaacGalleryEvents';
import IQACNavbar from './IQAC/IQACNavbar';
import Certificates2011 from './IQAC/Certificates2011';
import Certificates2017 from './IQAC/Certificates2017';
import Aqar1112 from './IQAC/Aqar11-12';
import Aqar1213 from './IQAC/Aqar12-13';
import Aqar1415 from './IQAC/Aqar14-15';
import Aqar1314 from './IQAC/Aqar13-14';
import Aqar1516 from './IQAC/Aqar15-16';
import Ssr201011 from './IQAC/Ssr2010-11';
import Ssr201617 from './IQAC/Ssr2016-17';
import Iqac2011 from './IQAC/Iqac2011';
import Iqac2017 from './IQAC/Iqac2017';
import Iqacfooter from './alumni/Home/Iqacfooter';
function IQAC() {
  return (
    <>
      <IQACNavbar />
      {/* <ScrollNavbar /> */}
      <Routes>
        <Route path="/" element={<IqacHome />} />
        <Route path="/certificate2011" element={<Certificates2011 />} />
        <Route path="/certificate2017" element={<Certificates2017 />} />
        <Route path="/aqar1112" element={<Aqar1112 />} />
        <Route path="/aqar1213" element={<Aqar1213 />} />
        <Route path="/aqar1314" element={<Aqar1314 />} />
        <Route path="/aqar1415" element={<Aqar1415 />} />
        <Route path="/aqar1516" element={<Aqar1516 />} />
        <Route path="/ssr20102011" element={<Ssr201011 />} />
        <Route path="/ssr20162017" element={<Ssr201617 />} />
        <Route path="/iqac2011" element={<Iqac2011 />} />
        <Route path="/iqac2017" element={<Iqac2017 />} />
        <Route path="/members" element={<Members />} />
        <Route path="/naacgallery" element={<NaacGallery />} />
        <Route path="/naacgallery/events/:id" element={<NaacGalleryEvents />} />
      </Routes>

      <Iqacfooter />
    </>
  );
}

export default IQAC;
