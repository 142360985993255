import React from 'react';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Button,
  Menu,
  MenuItem,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import DrawerComponent from './IqacDrawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IMAGElogo1 from './BAPULOGO.png';
import IMAGElogo2 from './BAPUJILO2.png';

const buttonStyles = {
  color: 'white',
  fontSize: '18px',
  fontFamily: 'Georgia, serif',
  '&:hover': {
    color: '#69b6b1'
    // backgroundColor: 'grey'
  }
};

const contactButtonStyles = {
  ...buttonStyles,
  marginRight: '40px' // Add margin to the right for Contact Us button
};

function IQACNavbar() {
  const [certAnchorEl, setCertAnchorEl] = React.useState(null);
  const [aqarAnchorEl, setAqarAnchorEl] = React.useState(null);
  const [ssrAnchorEl, setSsrAnchorEl] = React.useState(null);
  const [iqacAnchorEl, setIqacAnchorEl] = React.useState(null);
  const [ngalleryAnchorEl, setNgalleryAnchorEl] = React.useState(null); // Added state for gallery menu
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleCertMenuOpen = (event) => {
    setCertAnchorEl(event.currentTarget);
  };

  const handleCertMenuClose = () => {
    setCertAnchorEl(null);
  };

  const handleAqarMenuOpen = (event) => {
    setAqarAnchorEl(event.currentTarget);
  };

  const handleAqarMenuClose = () => {
    setAqarAnchorEl(null);
  };

  const handleSsrMenuOpen = (event) => {
    setSsrAnchorEl(event.currentTarget);
  };

  const handleSsrMenuClose = () => {
    setSsrAnchorEl(null);
  };

  const handleIqacMenuOpen = (event) => {
    setIqacAnchorEl(event.currentTarget);
  };

  const handleIqacMenuClose = () => {
    setIqacAnchorEl(null);
  };

  const handleNgalleryMenuOpen = (event) => {
    setNgalleryAnchorEl(event.currentTarget);
  };

  const handleNgalleryMenuClose = () => {
    setNgalleryAnchorEl(null);
  };

  return (
    <div>
      <Grid container spacing={1} sx={{ alignItems: 'center', marginBottom: '20px' }}>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
          <img src={IMAGElogo2} alt="Logo" style={{ width: '25%' }} />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <Typography
            sx={{ marginTop: '30px', fontSize: isMobile ? '15px' : '26px', fontWeight: 'bold' }}
          >
            BAPUJI DENTAL COLLEGE & HOSPITAL, DAVANGERE
          </Typography>
          <Typography
            sx={{
              marginTop: '10px',
              fontSize: isMobile ? '15px' : '26px',
              color: '#0591b1',
              fontWeight: 'bold'
            }}
          >
            INTERNAL QUALITY ASSURANCE CELL (IQAC)
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'start' }}>
          <img src={IMAGElogo1} alt="Logo" style={{ width: '25%' }} />
        </Grid>
      </Grid>

      <AppBar position="static" sx={{ backgroundColor: '#0591b1' }}>
        <CssBaseline />
        <Toolbar sx={{ justifyContent: isMobile ? 'right' : 'center' }}>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div>
              <Button component={Link} to="/iqac" color="inherit" sx={buttonStyles}>
                HOME
              </Button>

              <Button
                color="inherit"
                sx={buttonStyles}
                onClick={handleCertMenuOpen}
                endIcon={<ExpandMoreIcon />}
              >
                CERTIFICATES
              </Button>

              <Menu
                anchorEl={certAnchorEl}
                open={Boolean(certAnchorEl)}
                onClose={handleCertMenuClose}
              >
                <MenuItem onClick={handleCertMenuClose} component={Link} to="/iqac/certificate2011">
                  Certificate Of Accreditation 2011
                </MenuItem>
                <MenuItem onClick={handleCertMenuClose} component={Link} to="/iqac/certificate2017">
                  Certificate Of Accreditation 2017
                </MenuItem>
              </Menu>

              <Button
                color="inherit"
                sx={buttonStyles}
                onClick={handleAqarMenuOpen}
                endIcon={<ExpandMoreIcon />}
              >
                AQAR
              </Button>

              <Menu
                anchorEl={aqarAnchorEl}
                open={Boolean(aqarAnchorEl)}
                onClose={handleAqarMenuClose}
              >
                <MenuItem onClick={handleAqarMenuClose} component={Link} to="/iqac/aqar1112">
                  2011-12
                </MenuItem>
                <MenuItem onClick={handleAqarMenuClose} component={Link} to="/iqac/aqar1213">
                  2012-13
                </MenuItem>
                <MenuItem onClick={handleAqarMenuClose} component={Link} to="/iqac/aqar1314">
                  2013-14
                </MenuItem>
                <MenuItem onClick={handleAqarMenuClose} component={Link} to="/iqac/aqar1415">
                  2014-15
                </MenuItem>
                <MenuItem onClick={handleAqarMenuClose} component={Link} to="/iqac/aqar1516">
                  2015-16
                </MenuItem>
              </Menu>

              <Button
                color="inherit"
                sx={buttonStyles}
                onClick={handleSsrMenuOpen}
                endIcon={<ExpandMoreIcon />}
              >
                SSR
              </Button>

              <Menu anchorEl={ssrAnchorEl} open={Boolean(ssrAnchorEl)} onClose={handleSsrMenuClose}>
                <MenuItem onClick={handleSsrMenuClose} component={Link} to="/iqac/ssr20102011">
                  SSR 2010-2011
                </MenuItem>
                <MenuItem onClick={handleSsrMenuClose} component={Link} to="/iqac/ssr20162017">
                  SSR 2016-2017
                </MenuItem>
              </Menu>

              <Button
                color="inherit"
                sx={buttonStyles}
                onClick={handleIqacMenuOpen}
                endIcon={<ExpandMoreIcon />}
              >
                IQAC Members
              </Button>

              <Menu
                anchorEl={iqacAnchorEl}
                open={Boolean(iqacAnchorEl)}
                onClose={handleIqacMenuClose}
              >
                <MenuItem onClick={handleIqacMenuClose} component={Link} to="/iqac/iqac2011">
                  Internal Quality Assurance Cell 2011
                </MenuItem>
                <MenuItem onClick={handleIqacMenuClose} component={Link} to="/iqac/iqac2017">
                  Internal Quality Assurance Cell 2017
                </MenuItem>
              </Menu>

              <Button component={Link} to="/iqac/naacgallery" color="inherit" sx={buttonStyles}>
                NAAC PEER TEAM VISIT PHOTOS
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default IQACNavbar;
