// Management.jsx
import React, { useEffect } from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, Container } from '@mui/material';
import img1 from './sss1.webp';
import img2 from './veeranna.webp';
import img3 from './prabha.webp';
import img4 from './nuthalik.webp';
import img5 from './malllikarjun.webp';
import img6 from './naddeshwar.webp';

import useMediaQuery from '@mui/material/useMediaQuery';
import AHeader from '../components/AHeader';

const Management = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const adminData = [
    {
      name: 'Dr. Shamanur Shivashankarappa',
      designation: 'Chairman',
      image: img1
    },
    {
      name: 'Sri. A.S Veeranna',
      designation: 'Member',
      image: img2
    },
    {
      name: 'Dr. Prabha Mallikarjun',
      designation: 'Member',
      image: img3
    },
    {
      name: 'Sri. Sampanna Muthalik',
      designation: 'Member',
      image: img4
    },
    {
      name: 'Sri. S.S Mallikarjun',
      designation: 'Member',
      image: img5
    },
    {
      name: 'Dr. Nandeeshwar D B',
      designation: 'Convener',
      image: img6
    }
    // Add more objects as needed
  ];

  return (
    <>
      <AHeader header={'MANAGEMENT'} />
      <Container>
        <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom sx={{ marginBottom: 2 }}>
          About Our Parent Body
        </Typography>
        <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom sx={{ marginBottom: 2 }}>
          Bapuji Educational Association (BEA)
        </Typography>
        <Typography sx={{ marginBottom: 4, fontSize: 18, textAlign: 'justify' }}>
          Bapuji Educational Association (BEA) rightly named after the Father of Our Nation 'Bapuji,
          was the Brain Child of a Team of Spirited Philanthropists led by visionary Dr. Shamanur
          Shivashankarappa and found its inception in the year 1958 following the inspirational
          foresight of Pandit Jawaharlal Nehru.
          <br />
          As a Devoted Freedom Fighter and the First Prime Minister of Free India, Nehru strongly
          believed that the only powerful weapon that can drive out illiteracy and poverty was
          Education which should reach every Indian through "Temples of wisdom" as he duly referred
          Educational Institutions as.
          <br />
          With such an ignited root, Bapuji Educational Association in over 50 years of her
          livelihood has not only branched out the shoots into many fields of education but also
          ensured quality in every step. From corporate science to health care science, the
          association has left a mark in the entire venture and the banner gives tough competition
          to her peers in the country.
          <br />
          Today BEA is a global face that attracts students from overseas who choose to pursue their
          education in her shadow of tradition and poise.
        </Typography>

        <Grid container spacing={8} justifyContent="center" marginTop="20px">
          {adminData.map((person, index) => (
            <Grid item key={index}>
              <Card
                sx={{
                  width: 300,
                  borderRadius: 2,
                  boxShadow: 3,
                  height: 400,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <CardMedia
                  component="img"
                  image={person.image}
                  alt={`${person.name}'s picture`}
                  sx={{
                    height: 300,
                    objectFit: 'cover',
                    objectPosition: 'top'
                  }}
                />
                <CardContent sx={{ padding: 1, flexGrow: 1, textAlign: 'center' }}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2
                    }}
                  >
                    {person.name}
                  </Typography>
                  <Typography variant="body2">{person.designation}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Management;
