import React from 'react';

import DepartmentCards from './DepartmentCards';
import AboutUsHome from './AboutUsHome';
import HorizontalNews from './HorizontalNews';
import colorConfig2 from 'app/color/colorConfig2';
import PrincipalMessage from './PrincipalMessage';
import Panoroma from './Panoroma';
import ResponsiveCarousel from './ResponsiveCarousel';
import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import BDCH_GIF from 'assets/BDCH_GIF.gif';
import { useEffect } from 'react';
import { useState } from 'react';

const Main = () => {
  let colorConfigs = colorConfig2;

  const {  bigtext } = colorConfigs;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginTop: '40px' }}>

      <Dialog open={isLoading} fullScreen>
        <DialogContent sx={{ backgroundColor: '#112D58' }}>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <img src={BDCH_GIF} alt="Loading..." />
          </Box>
        </DialogContent>
      </Dialog>
      <Panoroma />
      {/* <IsoCertified colorConfig={colorConfigs} /> */}
      <HorizontalNews colorConfig={colorConfigs} setIsLoading={setIsLoading} />
      <AboutUsHome colorConfig={colorConfigs} />
      <PrincipalMessage colorConfig={colorConfigs} />

      <Grid container xs={12} style={{ marginTop: '20px', marginBottom: '50px' }}>
        <Grid item xs={12}>
          <Typography
            style={{ color: bigtext, fontWeight: '4vh', fontSize: '25px', marginLeft: '25px' }}
          >
            <b>Why choose Us</b>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <ResponsiveCarousel colorConfig={colorConfigs} />
        </Grid>
      </Grid>

      {/* <NewWeb /> */}
      <DepartmentCards colorConfig={colorConfigs} />
    </div>
  );
};

export default Main;
