import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import cardContent from './cardContent.json';

function DepartmentCards({ colorConfig }) {
  const { bigtext } = colorConfig;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const CardClick = (url) => {
    navigate(`${url}`);
  };

  return (
    <Grid container xs={12} style={{ paddingLeft: '20px', marginTop: '4vh', marginBottom: '5vh' }}>
      <Grid item xs={12} style={{ marginBottom: '15px' }}>
        <Typography style={{ color: bigtext, fontWeight: '4vh', fontSize: '25px' }}>
          <b>Departments</b>
        </Typography>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ paddingLeft: isMobile ? '10px' : '5Vh', paddingRight: isMobile ? '10px' : '5Vh' }}
      >
        {cardContent.map((content, index) => (
          <Grid item key={index} xs={12} sm={6} md={3} lg={3}>
            <Card
              sx={{
                maxWidth: 320,
                margin: '10px',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
                }
              }}
              onClick={() => CardClick(content.url)}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  sx={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover'
                  }}
                  image={content.image}
                  alt={'Dep Images'}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ textAlign: 'center', fontSize: '16px', color: bigtext }}
                  >
                    {content.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default DepartmentCards;
