//for nav bar:"#102c57"
//for card :"#7d8aa0"

import React, { useState } from 'react';
import {
  Grid,
  CssBaseline,
  Toolbar,
  Typography,
  useMediaQuery,
  AppBar,
  Button,
  Link
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import backgroundImage from '../../../../assets/header1.jpg';
import logo from '../header.png';
import imagee1 from './bapujibg.jpg';
import imagee2 from './SS.jpg';
import imagee3 from './ssm.jpg';
import imagee4 from './pres.jpeg';
import imagee5 from './vc.jpeg';
import VerticalScroll from './VerticalScroll'; // Import the VerticalScroll component
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import image1 from './ashok.jpeg';
import image2 from './prashanth.jpeg';
import CustomCard from './Cards.jsx';
import image3 from './pujac.jpeg';
import image4 from './trivenimg.jpeg';
import image5 from './shivaprasad.jpeg';
import image6 from './ahmedmujid.jpeg';
import image7 from './dhanyakumar.jpeg';
import image8 from './pres.jpeg';
import image9 from './lawrence.jpeg';
import image10 from './hckirankumar.jpeg';
import image11 from './nmdhanyakumar.jpeg';
import image12 from './atprakash.jpeg';
import image13 from './raisonthomas.jpeg';
import image14 from './shambulingappa.jpeg';
import image15 from './vc.jpeg';
import image16 from './shashikanth.jpeg';
import image17 from './barontaarun.jpeg';
import image18 from './aliim.jpeg';
import image19 from './sangeethasiddabasappa.jpeg';
import image20 from './sunithan.jpeg';
import HorizontalNews from 'app/views/home/HorizontalNews';
import colorConfig2 from 'app/color/colorConfig2';
const theme = createTheme({
  palette: {
    primary: {
      // main: '#fdc735',
      main: '#750E21'
    }
  }
});

const buttonStyles = {
  color: 'white',
  fontSize: '18px',
  '&:hover': {
    color: '#69b6b1',
    backgroundColor: 'transparent'
  }
};

const contactButtonStyles = {
  ...buttonStyles,
  marginRight: '40px',
  backgroundColor: '#feffff',
  color: '#750e21'
};

function AlumniHome2(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const alumniData = [
    // Leadership Positions
    { image: image8, name: 'Dr. D.B. Nandeeshwar', designation: 'President' },
    { image: image18, name: 'Dr. Ali I.M.', designation: 'Vice President' },
    { image: image15, name: 'Dr. Shashidhara H.S.', designation: 'Honorary Secretary' },
    { image: image17, name: 'Dr. Baron Tarun Kumar', designation: 'Honorary Joint Secretary' },
    { image: image12, name: 'Dr. A.T. Prakash', designation: 'Honorary Treasurer' },
    { image: image20, name: 'Dr. Sunitha N. Shamnur', designation: 'Editor in Chief' },
    { image: image19, name: 'Dr. Sangeeth Siddabasappa', designation: 'Associate Editor' },
    { image: image3, name: 'Dr Puja C. Yavagal', designation: 'Assistant Editor' },

    // Executive Committee Members
    { image: image1, name: 'Dr. Ashok L.', designation: 'Executive Committee Member' },
    { image: image14, name: 'Dr. Shambulingappa P.', designation: 'Executive Committee Member' },
    { image: image5, name: 'Dr. S. Shivaprasad', designation: 'Executive Committee Member' },
    { image: image11, name: 'Dr. N.M. Dhanya Kumar', designation: 'Executive Committee Member' },
    { image: image4, name: 'Dr. M.G. Triveni', designation: 'Executive Committee Member' },
    { image: image16, name: 'Dr. Shashikanth M.C.', designation: 'Executive Committee Member' },
    { image: image6, name: 'Dr. Ahmed Mujib B.R.', designation: 'Executive Committee Member' },
    { image: image7, name: 'Dr. B.H. Dhanya Kumar', designation: 'Executive Committee Member' },
    { image: image10, name: 'Dr. H.C. Kiran Kumar', designation: 'Executive Committee Member' },
    { image: image2, name: 'Dr. Prashanth G.M', designation: 'Executive Committee Member' },
    { image: image13, name: 'Dr. Raison Thomas', designation: 'Executive Committee Member' },
    { image: image9, name: 'Dr. Denzy Lawrence', designation: 'Executive Committee Member' }
  ];

  const isMobile = useMediaQuery('(max-width:600px)');

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 20
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function to handle opening the dropdown menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the dropdown menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: 'transparent',
          color: 'white',
          border: 'none',
          padding: '10px',
          cursor: 'pointer',
          zIndex: 1
        }}
      >
        &lt;
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: 'transparent',
          color: 'white',
          border: 'none',
          padding: '10px',
          cursor: 'pointer',
          zIndex: 1
        }}
      >
        &gt;
      </button>
    );
  };
  let colorConfigs = colorConfig2;
  const { textColor, bigtext } = colorConfigs;
  return (
    <div>
      <HorizontalNews colorConfig={colorConfigs} setIsLoading={setIsLoading} alumninews={true} />
      <div
        style={{
          position: 'relative',
          backgroundImage: `url(${imagee1})`,
          backgroundPosition: 'center',
          height: isMobile ? '150vh' : '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '0px',
          backgroundSize: 'cover'
        }}
      >
        {/* {isMobile && ( */}
        <div
          style={{
            position: 'absolute',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            zIndex: 1,
            marginTop: isMobile ? '-60px' : '5px'
          }}
        >
          <h1>PATRONS</h1>
        </div>
        {/* )} */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 1, 0.5)'
          }}
        ></div>

        <Grid container justify="center" spacing={6}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}
              >
                <div style={{ textAlign: 'center', color: 'white', marginBottom: '-7px' }}>
                  <img
                    src={imagee2}
                    alt="Image2"
                    style={{
                      width: '100%',
                      maxWidth: '350px',
                      height: '400px',
                      filter: 'brightness(80%)',
                      objectFit: 'contain',
                      objectPosition: 'center center'
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}
              >
                <div style={{ textAlign: 'center', color: 'white', marginBottom: '-7px' }}>
                  <img
                    src={imagee3}
                    alt="Image3"
                    style={{
                      width: '100%',
                      maxWidth: '350px',
                      height: '400px',
                      filter: 'brightness(80%)',
                      objectFit: 'contain',
                      objectPosition: 'center center'
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div style={{ margin: '50px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: '20px', textAlign: 'center' }}>
              <img
                src={imagee4}
                alt="president"
                style={{
                  width: '250px',
                  height: '300px',
                  display: 'block',
                  margin: '0 auto'
                }}
              />
              <Typography align="center" variant="h5" style={{ color: '#750e21' }}>
                PRESIDENT'S MESSAGE
              </Typography>
              <Typography align="center" style={{ fontSize: '20px' }}>
                DR. D. B. NANDEESHWAR
              </Typography>
            </div>
            <VerticalScroll
              content=" It is a pride privilege for every alumnus of this great institution
                which has given birth to excellent academicians, dedicated clinicians, renowned entrepreneurs,
                committed research scientists, versatile faculty and empowered dental education globally under the able leadership of various esteemed
                stalwarts of dentistry with highest administrative skills and extraordinary teachers who are moulding the character of our students.
                Each year, as we welcome new students to BDCH we are reminded of the great legacy left behind by our wonderful alumni.
                The Alumni Association will continue its mission to raise awareness, to grow its membership base, to assist with development of community based opportunities,
                and to promote exciting activities and events for alumni in the area and beyond. I request every alumnus to be the part of your Alma Mater and to serve for the betterment of association
                and further development activities in campus.
           "
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: '20px', textAlign: 'center' }}>
              <img
                src={imagee5}
                alt="seceretary"
                style={{
                  width: '250px',
                  height: '300px',
                  display: 'block',
                  margin: '0 auto'
                }}
              />
              <Typography align="center" variant="h5" style={{ color: '#750e21' }}>
                HON.SECRETARY'S MESSAGE
              </Typography>
              <Typography align="center" style={{ fontSize: '20px' }}>
                Dr. H. S. SHASHIDHARA
              </Typography>
            </div>
            <VerticalScroll
              content=" As Secretary of the Bapuji Dental College Alumni Association, I consider it an honour to welcome you all to this alumni portal.
                It is indeed my earnest desire to bring the our alumni closer through this medium, and I truly believe this will reconnect all of us.
                We are the foremost brand ambassadors and can play a vital role in the development of our Alma mater with our ideas and suggestions.

                We wish to nurture a rich culture among the alumni which leads to building a strong and connected alumni network in our field of dental
                education and services. We have many initiatives planned for the coming years and many innovative projects in the offing to increase member benefits and
                interaction and promote student welfare. I on behalf of the Alumni Association also take this opportunity to call upon our all alumni
                wherever they are to partner with us and lend us your co-operation and assistance to deliver on this challenge and make this vision of a vibrant and happening Alumni Association a reality..
          "
            />
          </Grid>
        </Grid>

        <div style={{ backgroundColor: ' #2360BD', paddingLeft: '60px', marginTop: '50px' }}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4,
                partialVisibilityGutter: 40
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3,
                partialVisibilityGutter: 30
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                partialVisibilityGutter: 20
              }
            }}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            slidesToSlide={1}
            partialVisibility={true}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {alumniData.map((alumni, index) => (
              <div
                key={index}
                style={{
                  padding: '5px',
                  height: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center', // Align text center
                  marginLeft: '5px'
                }}
              >
                <img
                  src={alumni.image}
                  alt={`Item ${index + 1}`}
                  style={{
                    alignSelf: 'center',
                    maxWidth: '200px',
                    height: '230px',
                    objectFit: 'cover',
                    marginLeft: isMobile ? '0px' : '0px',
                    marginRight: isMobile ? '50px' : '0px'
                  }}
                />
                <p
                  style={{
                    textAlign: 'center',
                    marginTop: isMobile ? '25px' : '35px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    marginRight: isMobile ? '30px' : '0px'
                  }}
                >
                  {alumni.name}
                  <br /> {alumni.designation}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px 10px',
          textAlign: 'center',
          marginLeft: isMobile ? '20px' : '25px'
        }}
      >
        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
          {/* <Grid item xs={12} sm={4}>
            <CustomCard sx={{ title: { color: '#C7B7A3' } }} title="NEWS" content="newssss" />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <CustomCard
              sx={{ title: { color: '#C7B7A3' } }}
              title="MOTTO OF BEAUIDEAL"
              content={
                <div style={{ textAlign: 'left', fontSize: '20px' }}>
                  {''}
                  "Honoring the Past, Shaping the Future of Dentistry.
                  <br />
                  Empowering Excellence, Inspiring Innovation, Uniting Generations of Knowledge and
                  Care."
                </div>
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomCard
              sx={{
                title: { color: '#C7B7A3' },
                content: { textAlign: 'left' }
              }}
              title="Contact INFO"
              content={
                <div style={{ textAlign: 'left', fontSize: '17px' }}>
                  {' '}
                  DR. H. S. Shashidhara <br />
                  Hon. Secretary, BEAUIDEAL <br />
                  Professor,
                  <br /> Dept. of Prosthodontics <br />
                  College of Dental Sciences, Davanagere <br />
                  Email: <a href="mailto:hebbalshashi@gmail.com">hebbalshashi@gmail.com</a>
                </div>
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AlumniHome2;
