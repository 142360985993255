import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
  Button
} from '@mui/material';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import DepButtons from './DepButtons';
import { useParams } from 'react-router-dom';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import { getFacultyDetailsByDept } from '../BackOfiice/components/UtilityFunctions';
import { useState } from 'react';
import { useEffect } from 'react';
import img1 from './image.png';
import BDCH_GIF from 'assets/BDCH_GIF.gif';
import { CleaningServices } from '@mui/icons-material';

const Faculty = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // console.log('overview', id);
  const [facultyArray, setFacultyArray] = useState([]);
  const [facultydata, setFacultyData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getFacultyDetailsByDept(setFacultyData, id, setLoading);
  }, [id]);

  useEffect(() => {
    if (facultydata.length >= 1) {
      setFacultyArray(sortedArray);
    }
    console.log(facultydata);
  }, [facultydata]);

  const sortedArray = facultydata.sort((a, b) => {
    if (a.prioritywise !== undefined && b.prioritywise !== undefined) {
      return a.prioritywise - b.prioritywise;
    } else if (a.prioritywise !== undefined) {
      return -1;
    } else if (b.prioritywise !== undefined) {
      return 1;
    } else {
      return 0;
    }
  });

  const department = departmentData[id];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openPDF = (pdfUrl, title) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        pdfUrl +
        '" title="' +
        title +
        '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <Grid container style={{ marginTop: isMobile ? '8vh' : '28vh' }}>
      {isMobile ? <DepNameBar id={id} department={department}></DepNameBar> : null}

      {isMobile ? null : (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            {department?.name} Faculty's
          </Typography>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: 'black',
              height: '2px',
              width: '90%',
              margin: { xs: '10px auto', sm: '10px auto' }
            }}
          />
        </Grid>
      )}

      <Grid container style={{ paddingTop: '10px', justifyContent: 'center' }}>
        {/* Left side with DepButtons */}
        {isMobile ? null : (
          <Grid item xs={12} md={2} style={{ paddingRight: '20px' }}>
            <DepButtons id={id} />
          </Grid>
        )}

        {/* Middle section with Card components for Faculty */}
        <Grid item xs={12} md={8}>
          {facultyArray?.length > 0 &&
            facultyArray?.map((faculty, index) => (
              <Card key={index} style={{ marginBottom: '2vh', overflow: 'hidden' }}>
                <CardContent>
                  <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{
                        alignItems: 'center',
                        paddingRight: '2vh',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      {faculty?.facProfile ? (
                        <img
                          src={faculty?.facProfile}
                          alt={faculty?.name?.firstName}
                          style={{
                            width: '100%',
                            height: 'auto',
                            border: '2px solid black',
                            marginBottom: '2vh',
                            fontWeight: 'bold'
                          }}
                        />
                      ) : (
                        <img
                          src={img1}
                          alt={faculty?.name?.firstName}
                          style={{
                            width: '100%',
                            height: 'auto',
                            border: '2px solid black',
                            marginBottom: '2vh',
                            fontWeight: 'bold'
                          }}
                        />
                      )}

                      <Typography>
                        {' '}
                        Dr.&nbsp;{faculty?.name?.firstName} {faculty?.name?.lastName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <TableContainer component={Paper} style={{ padding: '2vh' }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left" style={{ width: '30%' }}>
                                Name
                              </TableCell>
                              <TableCell>
                                <>
                                  Dr. {faculty?.name?.firstName} {faculty?.name?.lastName}
                                </>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" style={{ width: '30%' }}>
                                Designation
                              </TableCell>
                              <TableCell>{faculty?.designation}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" style={{ width: '30%' }}>
                                Qualification
                              </TableCell>
                              <TableCell>{faculty?.qualification}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                              <TableCell align="left" style={{ width: '30%' }}>
                                Contact
                              </TableCell>
                              <TableCell>{faculty?.contact}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" style={{ width: '30%' }}>
                                Email
                              </TableCell>
                              <TableCell>{faculty?.email}</TableCell>
                            </TableRow> */}
                            {faculty?.areaOfInterest && (
                              <TableRow>
                                <TableCell
                                  align="left"
                                  style={{ width: '30%', verticalAlign: 'top' }}
                                >
                                  Area of Intrest
                                </TableCell>
                                <TableCell style={{ textAlign: 'justify', wordBreak: 'keep-all' }}>
                                  {faculty?.areaOfInterest}
                                </TableCell>
                              </TableRow>
                            )}
                            {faculty?.achievement.length > 0 &&
                              faculty?.achievement.some((item) => item !== '') && (
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    style={{ width: '30%', verticalAlign: 'top' }}
                                  >
                                    Achievement
                                  </TableCell>
                                  <TableCell
                                    style={{ textAlign: 'justify', wordBreak: 'keep-all' }}
                                  >
                                    {faculty?.achievement &&
                                      faculty?.achievement.map((item, index) => (
                                        <Grid
                                          key={index}
                                          container
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'nowrap'
                                          }}
                                        >
                                          <Grid item xs={1}>
                                            {index + 1}.
                                          </Grid>
                                          <Grid
                                            item
                                            xs={11}
                                            style={{ textAlign: 'justify', wordBreak: 'keep-all' }}
                                          >
                                            {item}
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </TableCell>
                                </TableRow>
                              )}

                            {faculty?.pdfFiles && (
                              <TableRow>
                                <TableCell align="left" style={{ width: '30%' }} />

                                <TableCell>
                                  <Button
                                    onClick={() =>
                                      openPDF(faculty?.pdfFiles, faculty?.name?.firstName)
                                    }
                                  >
                                    Please Click here
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
        </Grid>

        {/* Right side with VerticalNewsBanner */}
        {/* <Grid item xs={12} md={3} style={{ paddingLeft: '6vh' }}>
          <VerticalNewsBanner />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Faculty;
