import {
  getDepartmentDetails,
  getDepartmentDetailsByDeptType,
  getFacultyDetailsByDeptType,
  getPublicationsByDeptType,
  getAwardsByDeptType,
  getDissertationsByDeptType,
  getPresentationsByDeptType
} from 'api-ws/department';

export const fetchDepartmentDetails = (setDepartmentArray, setIsLoading) => {
  getDepartmentDetails()
    .then((res) => {
      setIsLoading(false);
      const newArray = res.data.data.map((dept) => ({
        label: dept.title,
        value: dept.deptType
      }));
      setDepartmentArray(newArray);
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error);
    });
};

export const getFacultyDetailsByDept = (setFacultyArray, deptType, setIsLoading) => {
  getFacultyDetailsByDeptType(deptType)
    .then((res) => {
      setFacultyArray(res.data.data);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchPublicationsByDept = (value, setPublications, setLoading) => {
  // console.log('DSADa', value);
  setLoading(true);
  getPublicationsByDeptType(value)
    .then((res) => {
      // console.log('by dept', res.data.data.pdfFiles);
      setPublications(res.data.data.pdfFiles);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
};

export const fetchPresentationsByDept = (value, setPresentation, setLoading) => {
  setLoading(true);
  // console.log('DSADa', value);
  getPresentationsByDeptType(value)
    .then((res) => {
      // console.log('by dept', res.data.data.pdfFiles);
      setLoading(false);
      setPresentation(res.data.data.pdfFiles);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchAwardsByDept = (value, setAwards, setLoading) => {
  setLoading(true);
  getAwardsByDeptType(value)
    .then((res) => {
      // console.log('by dept', res.data.data.pdfFiles);
      setLoading(false);
      setAwards(res.data.data.pdfFiles);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchDissertationByDept = (value, setDissertation, setLoading) => {
  setLoading(true);
  getDissertationsByDeptType(value)
    .then((res) => {
      // console.log('by dept', res.data.data.pdfFiles);
      setDissertation(res.data.data.pdfFiles);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const returnBase64 = (event, setEvent) => {
  const file = event.target.files[0];

  if (file && file.size > 2 * 1024 * 1024) {
    alert('File size exceeds 2 MB. Please upload a smaller file.');
    return; 
  }
  const reader = new FileReader();

  reader.onloadend = () => {
    const base64String = reader.result;
    const fileName = file.name;

    // You can create an object to store both the base64 string and file name
    const fileData = {
      base64: base64String,
      fileName: fileName
    };

    setEvent(fileData);
  };

  reader.readAsDataURL(file);
};
