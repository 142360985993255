import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';

const Boyshostel = () => {
  const boyshostelData = [
    {
      name: 'BAPUJI INTERNATIONAL HOSTEL FOR BOYS',
      totalRooms: 140,
      capacity: 230,
      telephone: '91-8192-224611, 8892534447',
      recreation: 'Newspapers, Magazines, T.V., Gymnasium, Table Tennis, Caroms, Chess, Shuttle',
      mess: 'Veg / Non veg.',
      warden: 'Dr. Baron Tarun Kumar A.'
    },
    {
      name: "DENTAL COLLEGES' BOYS HOSTEL",
      totalRooms: 260,
      capacity: '178 - Twin Sharing & 82 - Single Occupancy',
      telephone: '91-8192-220478',
      recreation: 'Newspapers, Magazines, T.V., Gymnasium, Table Tennis, Caroms, Chess, Shuttle',
      mess: 'Veg / Non veg.',
      warden: 'Dr. B. H. Dhanya Kumar'
    }
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Grid container spacing={3} sx={{ marginTop: '25px', marginBottom: '20px' }}>
        <Grid item xs={12}>
          <Typography
            variant={isSmallScreen ? 'h5' : 'h4'}
            gutterBottom
            sx={{ textAlign: 'center', marginBottom: '25px' }}
          >
            Boys Hostel Information
          </Typography>
        </Grid>

        <TableContainer component={Paper} sx={{ overflowX: 'auto', marginLeft: '15px' }}>
          <Table sx={{ minWidth: 320 }} aria-label="boys hostel information table">
            <TableBody>
              {boyshostelData.map((hostel, index) => (
                <React.Fragment key={index}>
                  {isSmallScreen ? (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            backgroundColor: '#0180A6',
                            color: 'white',
                            padding: '10px',
                            paddingLeft: '20px'
                          }}
                        >
                          {hostel.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography sx={{ fontSize: '14px', paddingLeft: '10px' }}>
                            <b>Total Rooms:</b> {hostel.totalRooms}
                          </Typography>
                          <Typography sx={{ fontSize: '14px', paddingLeft: '10px' }}>
                            <b>Maximum Capacity:</b> {hostel.capacity}
                          </Typography>
                          <Typography sx={{ fontSize: '14px', paddingLeft: '10px' }}>
                            <b>Telephone Nos:</b> {hostel.telephone}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              paddingLeft: '10px',
                              wordBreak: 'break-word',
                              whiteSpace: 'normal',
                              overflowWrap: 'break-word'
                            }}
                          >
                            <b>Recreation Facilities:</b> {hostel.recreation}
                          </Typography>
                          <Typography sx={{ fontSize: '14px', paddingLeft: '10px' }}>
                            <b>Mess Facility:</b> {hostel.mess}
                          </Typography>
                          <Typography sx={{ fontSize: '14px', paddingLeft: '10px' }}>
                            <b>Warden:</b> {hostel.warden}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          paddingLeft: '15px',
                          textAlign: 'left',
                          fontWeight: 'bold',
                          fontSize: '18px',
                          backgroundColor: '#0180A6',
                          color: 'white',
                          borderBottom: '1px solid rgba(224, 224, 224, 1)'
                        }}
                      >
                        {hostel.name}
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: '16px', paddingLeft: '10px' }}>
                          <b>Total Rooms:</b> {hostel.totalRooms}
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingLeft: '10px' }}>
                          <b>Maximum Capacity:</b> {hostel.capacity}
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingLeft: '10px' }}>
                          <b>Telephone Nos:</b> {hostel.telephone}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            paddingLeft: '10px',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            overflowWrap: 'break-word'
                          }}
                        >
                          <b>Recreation Facilities:</b> {hostel.recreation}
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingLeft: '10px' }}>
                          <b>Mess Facility:</b> {hostel.mess}
                        </Typography>
                        <Typography sx={{ fontSize: '16px', paddingLeft: '10px' }}>
                          <b>Warden:</b> {hostel.warden}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

export default Boyshostel;
