import { Typography, Link, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

const Procedure = ({ procedureData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} style={{ padding: '40px' }}>
        <div
          style={{
            maxWidth: '1000px',
            margin: '0 auto',
            marginBottom: '10px'
          }}
        >
          <Typography
            variant="h5"
            style={{
              textAlign: 'center',
              marginBottom: '30px',
              color: '#0352A1',
              fontWeight: 'bold',
              marginTop: '30px'
            }}
          >
            ADMISSION PROCEDURE FOR BDS & MDS COURSES
          </Typography>

          <Typography
            variant="h6"
            style={{
              textDecoration: 'underline',
              marginBottom: '30px',
              textAlign: isMobile ? 'center' : 'justify',
              fontSize: isMobile ? '18px' : '20px'
            }}
          >
            All the seats are allotted by merit through Online counseling conducted by Karnataka
            Examinations Authority
          </Typography>
          <p
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              textAlign: 'justify'
            }}
          >
            NEET qualified students have to register in Karnataka Examination Authority website for
            all category of seats for admission to BDS & MDS Courses ( Govt. Quota Seats, Private
            Open Quota Seats & NRI/ Management Seats).
          </p>

          <p
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              textAlign: isMobile ? 'left' : 'justify'
            }}
          >
            Regarding registration, document verification, fee structure, seat matrix, cut off rank
            details, counseling and other procedures, follow the steps below:
          </p>

          <Typography variant="h6" style={{ marginBottom: '10px' }}>
            Karnataka Examination Authority Website:
            <Link href={'http://www.kea.kar.nic.in'} target="_blank" rel="noopener noreferrer">
              www.kea.kar.nic.in
            </Link>{' '}
          </Typography>
          <Typography
            variant="h5"
            style={{
              marginBottom: '10px',
              color: '#0352A1',
              fontWeight: 'bold',
              marginTop: '20px'
            }}
          >
            BDS ADMISSIONS :
          </Typography>
          <ol style={{ fontSize: '18px', paddingLeft: '20px', listStyleType: 'decimal' }}>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                Candidates must have passed individually in the subjects of Physics, Chemistry,
                Biology and English and must have obtained a minimum of not less than 50% marks in
                Physics, Chemistry & Biology taken together in the qualifying Examination. In
                respect of Scheduled Castes/ Scheduled Tribes and Category I, the minimum marks
                prescribed shall be not less than 40% of the total marks in lieu of 50% marks for
                general candidates.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                The candidate shall have completed the age of 17 years at the time of admission or
                will complete this age on 31st December of the year in which he/ she seeks
                admission.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                Students studied other than pre-university board of Karnataka have to produce
                Eligibility Certificate issued by Rajiv Gandhi University of Health Sciences,
                Karnataka at the time of BDS admission.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                DURATION OF THE COURSE : 4 YEARS PLUS ONE YEAR COMPULSORY INTERNSHIP.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={{ fontSize: '1.05rem' }}>
                Should be NEET qualified
              </Typography>
            </li>
          </ol>
          <Typography
            variant="h5"
            style={{
              marginBottom: '10px',
              color: '#0352A1',
              fontWeight: 'bold',
              marginTop: '20px'
            }}
          >
            MDS ADMISSIONS :
          </Typography>
          <ol style={{ fontSize: '18px', paddingLeft: '20px', listStyleType: 'decimal' }}>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                A candidate for admission to the MDS course must have a recognized degree of BDS
                awarded by an Indian University in respect of recognized Dental College under
                section 10(2) of the Dentists Act, 1948 or an equivalent qualification recognized by
                the Dental Council of India and should have obtained permanent registration with the
                State Dental Council. Candidates not possessing a recognized Dental qualification
                for the above purpose should secure the prior approval of his qualifications by the
                Dental Council of India before he can be admitted to the MDS Course of any
                University in India.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                Students studied other than Rajiv Gandhi University of Health Sciences, Karnataka
                have to produce Eligibility Certificate issued by Rajiv Gandhi University of Health
                Sciences, Karnataka at the time of MDS admission.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                Candidate should have completed one year compulsory rotating internship after
                passing the final professional examination.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                DURATION OF THE COURSE : 3 YEARS
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                Should be NEET qualified
              </Typography>
            </li>
          </ol>
          <Typography
            variant="h5"
            style={{
              marginBottom: '10px',
              color: '#0352A1',
              fontWeight: 'bold',
              marginTop: '20px'
            }}
          >
            Admission for Ph.D Courses :
          </Typography>
          <p style={{ fontSize: '1.05rem', marginBottom: '10px', textAlign: 'justify' }}>
            Our college has been recognized as Ph.D. centre for Oral Medicine & Radiology,
            Paedodontics & Preventive Dentistry, Orthodontics & Dentofacial Orthopedics and
            Periodontology.{' '}
          </p>
          <ul style={{ fontSize: '18px', paddingLeft: '25px', listStyleType: 'circle' }}>
            <li>
              <Typography sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                Interested Teaching Faculty, BDS & MDS students may apply.
              </Typography>
            </li>
            <li>
              <Typography sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                For more details visit RGUHS website: www.rguhs.ac.in.
              </Typography>
            </li>
          </ul>

          <Typography
            variant="h5"
            style={{
              marginBottom: '10px',
              color: '#0352A1',
              fontWeight: 'bold',
              marginTop: '20px'
            }}
          >
            Eligibility Criteria for Admission :
          </Typography>
          <ol style={{ fontSize: '18px', paddingLeft: '20px', listStyleType: 'decimal' }}>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                Candidates with MDS degree awarded by RGUHS University or by a recognized
                College/University recognized by DCI or a PG Dental degree considered as equivalent
                by RGUHS are eligible for enrollment for Ph.D. course in Dentistry.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                RGUHS University resolved to allow BDS Graduates to the Ph.D. programmes. The
                duration of these Ph.D. Programmes shall be of five years on full time basis only.
                The other conditions specified in regulations governing Ph.D. course notified shall
                also apply to these programmes.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: '1.05rem' }}>
                The interdisciplinary Ph.D. registrations in Dental faculties are allowed to improve
                the research activities with the condition that co-guide from the parent department
                and guide from the interdisciplinary faculties.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                sx={{ textAlign: 'justify', fontSize: '1.05rem', marginBottom: '20px' }}
              >
                The candidates with Medical M.Sc. degrees working in Dental Colleges, can be
                considered eligible to register for the Ph.D. degree in RGUHS under Dental faculty
                under allied science groups and degree will be awarded with a different nomenclature
                as “Ph.D. in Allied health Sciences (Non Medical subject to be specified).
              </Typography>
            </li>
          </ol>
        </div>
      </Grid>
    </Grid>
  );
};

export default Procedure;
