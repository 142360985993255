import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import img1 from './aboutbdchpic.webp';
import img2 from './gradf.webp';
import img3 from './bdch.webp';
import VmSection from './visionmission';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        sx={{
          height: { xs: '60vh', sm: '80vh' },
          backgroundImage: `url(${img1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: { xs: '10px', sm: '20px' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.4)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: { xs: '5px', sm: '5px' },
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'Georgia',
              color: '#ffffff',
              fontSize: { xs: '20px', sm: '32px' },
              fontWeight: 'bold',
              mb: 2
            }}
          >
            About BDCH
          </Typography>
          <Typography
            variant="h3" // Changed from h5 to h3
            sx={{
              fontFamily: 'Georgia',
              color: '#ffffff',
              fontSize: { xs: '14px', sm: '26px' },
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            Nurturing Thousands of Dentists to reach the Zenith of their Profession.
          </Typography>
        </Box>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: 'auto',
          marginTop: '20px',
          textAlign: 'center',
          marginBottom: '20px',
          padding: { xs: '10px', sm: '0px' }
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '36px' },
            fontFamily: 'Satoshi',
            width: '100%',
            whiteSpace: 'pre-line'
          }}
        >
          This College has established itself as a Highly Vibrant {'\n'}and Dynamic Institution in
          the Realm of Dental Education
        </Typography>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          marginTop: '30px',
          marginLeft: { xs: '0px', sm: '40px' },
          marginRight: { xs: '10px', sm: '0px' },
          padding: { xs: '10px', sm: '0px' }
        }}
      >
        <Grid item xs={12} sm={6}>
          <img src={img2} alt="Student Stats" style={{ width: '100%', height: 'auto' }} />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
          <Typography
            sx={{ fontSize: { xs: '24px', sm: '40px' }, color: 'red', marginBottom: '10px' }}
          >
            3800+ & 1350+
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '12px', sm: '20px' }, color: 'black', marginBottom: '20px' }}
          >
            Undergraduate and Postgraduate Students
          </Typography>

          <Typography
            sx={{ fontSize: { xs: '24px', sm: '40px' }, color: 'red', marginBottom: '10px' }}
          >
            88+
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '12px', sm: '20px' }, color: 'black', marginBottom: '20px' }}
          >
            Renowned Faculty Members
          </Typography>

          <Typography
            sx={{ fontSize: { xs: '24px', sm: '40px' }, color: 'red', marginBottom: '10px' }}
          >
            5000+
          </Typography>
          <Typography sx={{ fontSize: { xs: '12px', sm: '20px' }, color: 'black' }}>
            Alumni Worldwide
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          background: '#E8D8C4',
          marginTop: { xs: '10px', sm: '-8px' },
          padding: '20px'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: '20px', sm: '30px' },
            fontFamily: 'Satoshi',
            fontWeight: 'bold',
            textAlign: 'center',
            width: '100%',
            marginBottom: '20px'
          }}
        >
          History of Bapuji Dental College And Hospital
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={img3} alt="Bapuji Dental College" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', alignItems: 'center', textAlign: 'justify' }}
          >
            <Typography sx={{ fontSize: { xs: '14px', sm: '18px' } }}>
              Driven by a passion to establish World-Class Institutions, Bapuji Dental College was
              founded in 1979 by the esteemed Dr. Shamanur Shivashankarappa. Under the guidance of
              visionary leaders in Indian Dentistry, the college has experienced remarkable growth,
              celebrating its silver jubilee in 2004.
              <br />
              <br />
              Bapuji Dental College stands out as one of the most vibrant and dynamic institutions
              in Dental Education. It has nurtured thousands of dentists to reach the pinnacle of
              their profession. Since its inception, the college has significantly contributed to
              India’s rich legacy of knowledge, education, and enlightenment. The state-of-the-art
              institute offers students exceptional clinical education, complemented by research
              opportunities and community service.
              <br /> <br />
              The college features some of the finest lab facilities, including sophisticated
              phantom head labs with 100 Dental workstations for pre-clinical training, simulating
              real dental treatments. It also boasts a Ceramic Lab for crown and bridge fabrication,
              providing students with valuable technical skills in dentistry. Additionally, the
              college prides itself on its extensive library, which is well-ventilated and
              illuminated, creating an ideal environment for avid readers. The library is equipped
              with modern resources, including CD-ROMs, online databases, video cassettes, books,
              journals, back volumes, theses, and WHO publications. It serves as a comprehensive
              resource center for students, offering everything from references to printouts and
              binding services.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ width: '100%', padding: '20px', textAlign: 'center', background: '#F3F3F3' }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: { xs: '20px', sm: '30px' },
            fontWeight: 'bold',
            fontFamily: 'Satoshi',
            color: '#333',
            marginTop: '10px',
            marginBottom: '20px'
          }}
        >
          Our Vision & Mission
        </Typography>
        <VmSection />
      </Box>
    </Grid>
  );
};

export default AboutUs;
