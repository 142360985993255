import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addAlumniNews = (newsRequest, authToken) => {
  const urlPath = `/alumninews/addNews`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: newsRequest,
    additionalHeader: additionalHeader
  });
};

export const updateAlumniNews = (newsRequest, authToken) => {
  const urlPath = `/alumninews/updateNews`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: newsRequest,
    additionalHeader: additionalHeader
  });
};

export const viewAlumniNews = () => {
  const urlPath = `/alumninews/getAllNews`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const removeAlumniNewsById = (id, authToken) => {
  const urlPath = `/alumninews/removeNews/${id}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};
