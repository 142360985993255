import { Typography, Link, Grid, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';

const KeyContact = ({ keycontactData }) => {
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');

  const typographyVariant = isLargeScreen ? 'h6' : 'body1';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Grid container spacing={3} sx={{ marginTop: '20px', padding: '30px' }}>
        <Grid item xs={12} sm={6}>
          <Typography variant={typographyVariant}>THE PRINCIPAL</Typography>
          <Typography variant={typographyVariant}>BAPUJI DENTAL COLLEGE & HOSPITAL</Typography>
          <Typography variant={typographyVariant}>Post Box No.326. DAVANGERE – 577004.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant={typographyVariant}>
            Ph:- +91-08192-220573, 220575, 220579, 220580, 222800, 223000.
          </Typography>
          <Typography variant={typographyVariant}>
            E-mail:-{' '}
            <Link href="mailto:principal@bapujidental.edu">principal@bapujidental.edu</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default KeyContact;
