import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import Overview from './Overview';
import Collections from './Collections';
import Services from './Services';

import useMediaQuery from '@mui/material/useMediaQuery';
import AHeader from '../components/AHeader';
import ImageGallery from './libimagegallery';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-scrollable': {
    overflowX: 'auto' 
  },
  '.MuiTabs-flexContainer': {
    whiteSpace: 'nowrap' 
  },
  '.MuiTabs-indicator': {
    backgroundColor: '#0180A6' 
  },
  '.MuiTab-textColorInherit': {
    color: 'darkorange' 
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiTabs-scrollable': {
      overflowX: 'auto' 
    }
  }
}));

const LibraryPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)'); 

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabsData = {
    tabs: [
      {
        id: 0,
        label: '1-Overview',
        content: '<p>Application Procedures Content</p>',
        heading: 'bjhb',
        overview: {
          heading: 'ADMISSION PROCEDURE FOR BDS & MDS COURSES'
        }
      },
      {
        id: 1,
        label: '2-Collections',
        content: '<p>Application Form Content</p>',
        collections: {
          heading: 'af'
        }
      },
      {
        id: 2,
        label: '3-Facilities and Services',
        content: '<p>Content</p>',
        services: {
          heading: 'kc'
        }
      },
      {
        id: 3,
        label: '4-Image Gallery ',
        content: '<p>Key Contact Content</p>',
        imagegallery: {
          heading: 'kc'
        }
      }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AHeader header={'LIBRARY'} />
      <Grid container style={{ justifyContent: 'center' }}>
        <Grid item xs={12} md={9} style={{ justifyContent: 'center' }}>
          <div
            style={{
              padding: isMobile ? '10px' : '0px',
              width: '95%',
              justifyContent: 'center'
            }}
          >
            <Card
              style={{
                marginLeft: isMobile ? '25px' : '50px',
                padding: '30px',
                justifyContent: 'center'
              }}
            >
              <CustomTabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="black"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabsData.tabs.map((tab) => (
                  <Tab key={tab.id} label={tab.label} value={tab.id} />
                ))}
              </CustomTabs>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {tabsData.tabs.map(
                  (tab) =>
                    activeTab === tab.id && (
                      <div
                        key={tab.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center', 
                          marginLeft: '10px',
                          marginTop: '10px'
                        }}
                      >
                        {tab.overview ? <Overview overviewData={tab.overview} /> : null}
                        {tab.collections ? <Collections collectionsData={tab.collections} /> : null}
                        {tab.services ? <Services servicesData={tab.services} /> : null}
                        {tab.imagegallery ? (
                          <ImageGallery imageGalleryData={tab.imagegallery} />
                        ) : null}
                      </div>
                    )
                )}
              </div>
            </Card>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LibraryPage;
