import pdf from './ssr16-17.pdf';
import React, { useState, useEffect } from 'react';
import { Typography, Divider, Button, Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

function Ssr201617() {
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust the breakpoint as needed
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDocumentLoadSuccess = () => {
    setNumPages(numPages);
  };

  const openPDF = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      `<iframe src="${pdfUrl}" style="width:100%; height:100%; border:none;"></iframe>`
    );
  };

  const iframeWidth = isMobile ? '90%' : '80%';
  const iframeHeight = isMobile ? '400px' : '800px';

  return (
    <Box
      sx={{
        marginTop: '50px',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 'bold',
          fontSize: isMobile ? '24px' : '34px',
          textAlign: isMobile ? 'left' : 'center'
        }}
      >
        SSR 2016 - 2017
      </Typography>
      <Divider
        sx={{
          marginTop: '35px',
          marginBottom: '50px',
          width: { xs: '80%', md: '80%' }
        }}
      />

      {isMobile ? (
        <Button onClick={() => openPDF(pdf)} sx={{ marginBottom: '20px' }}>
          Open SSR 2016-2017 PDF <br /> click here
        </Button>
      ) : (
        <Box sx={{ width: '100%', overflowX: isMobile ? 'auto' : 'hidden' }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: '4vh',
              margin: '0 auto'
            }}
          >
            <iframe
              title="SSR 2010-2011 PDF"
              src={pdf}
              width={iframeWidth}
              height={iframeHeight}
              style={{ border: 'none' }}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default Ssr201617;
