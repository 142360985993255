import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Divider,
  Button,
  CardActionArea
} from '@mui/material';
import departmentData from './departmentData.json';
import DepNameBar from './DepNameBar';
import DepButtons from './DepButtons';
import ImageViewer from 'react-simple-image-viewer';
import { useEffect } from 'react';
import { useState } from 'react';
import { Hourglass } from 'react-loader-spinner';
import { useCallback } from 'react';

function Activities() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const srcArray = departmentData[id]?.Activity?.map((item) => item.img_src);
  console.log(departmentData?.[id], id);

  // console.log('Id:', id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const department = departmentData[id];
  // console.log('Department:', department?.Activities[0]?.pdf);

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  console.log(departmentData?.Activity);
  return (
    <Grid container style={{ marginTop: isMobile ? '7vh' : '25vh' }}>
      {isMobile ? <DepNameBar id={id} department={department}></DepNameBar> : null}

      {isMobile ? null : (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            {department?.name} Activities
          </Typography>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: 'black',
              height: '2px',
              width: '90%',
              margin: { xs: '10px auto', sm: '10px auto' }
            }}
          />
        </Grid>
      )}

      <Grid container style={{ paddingTop: '10px', justifyContent: 'center' }}>
        {/* DepButtons on the left */}
        {isMobile ? null : (
          <Grid item xs={12} md={2} style={{ paddingRight: '20px' }}>
            <DepButtons id={id} />
          </Grid>
        )}

        {/* Card in the middle */}
        {/* <Grid item xs={12} md={8} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Card>
            <CardContent>
              <Typography variant="h6" fontWeight="20px">
                Activities
              </Typography>

              {department?.Activities[0]?.pdf &&
                (isMobile ? (
                  <Button onClick={() => openPDF(department?.Activities[0]?.pdf)}>
                    {' '}
                    Activities? Please Click here
                  </Button>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    padding="4vh"
                    style={{
                      alignItems: 'center',
                      justifyItems: 'center'
                    }}
                  >
                    <iframe
                      title="Activities PDF"
                      src={department?.Activities[0]?.pdf}
                      width="100%"
                      height="800px"
                    />
                  </Grid>
                ))}

              {department?.Activities?.map((item, index) => (
                <div key={index}>
                  <Typography
                    style={{
                      fontFamily: 'georgia',
                      fontSize: '20px',
                      textAlign: 'left',
                      marginBottom: item?.addSpace ? '20px' : '0px'
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'left',
                        fontWeight: item?.bold ? 'bold' : 'none',
                        fontSize: '15px'
                      }}
                    >
                      {item?.heading}
                    </div>
                    <div
                      style={{
                        textAlign: 'justify',
                        fontWeight: item?.contentBold ? 'bold' : 'none',
                        fontSize: '15px'
                      }}
                    >
                      {item?.content}
                    </div>
                  </Typography>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} md={8}>
          <div>
            {loading && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 999
                }}
              >
                <Hourglass
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={['#97905E', '#000000']}
                />
              </div>
            )}
            <Grid
              container
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignContent: 'center'
              }}
            >
              <Grid container spacing={2}>
                {department?.Activity?.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index} justifyContent="center">
                    <Card
                      sx={{
                        maxWidth: 320,
                        margin: isMobile ? '30px' : '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
                        }
                      }}
                    >
                      <CardActionArea
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'stretch',
                          height: '100%',
                          width: '100%',
                          padding: 0,
                          margin: 0
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '0',
                            paddingBottom: '75%',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: 0
                          }}
                        >
                          <img
                            src={image.img_src}
                            onClick={() => openImageViewer(index)}
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                            alt={image.title}
                          />
                        </div>
                        <Typography
                          sx={{
                            marginTop: '8px',
                            textAlign: 'center',
                            padding: '0 8px',
                            flexGrow: 1,
                            margin: 0
                          }}
                        >
                          {image.title}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {isViewerOpen && (
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 9999 // Ensure it's above everything, including the navbar
                  }}
                >
                  <ImageViewer
                    src={srcArray}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                  />
                </div>
              )}
            </Grid>
          </div>
        </Grid>

        {/* VerticalNewsBanner on the right or below in mobile view */}
        {/* <Grid item xs={isMobile ? 12 : 3} style={isMobile ? { marginTop: '20px' } : null}>
          <VerticalNewsBanner />
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default Activities;
