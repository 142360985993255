import { useTheme } from '@emotion/react';
import { Divider, Grid, Typography, useMediaQuery, Button } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import departmentData from './departmentData.json';
import DepNameBar from './DepNameBar';
import DepButtons from './DepButtons';
import { fetchPresentationsByDept } from '../BackOfiice/components/UtilityFunctions';

import { useEffect } from 'react';
import BDCH_GIF from 'assets/BDCH_GIF.gif';

function Presentation() {
  const { id } = useParams();

  const department = departmentData[id];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [presentation, setPresentation] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPresentationsByDept(id, setPresentation, setLoading);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openPDF = (pdfUrl) => {
    var win = window.open();
    win.document.write(
      '<iframe src="' + pdfUrl + '" style="width:100%; height:100%; border:none;"></iframe>'
    );
  };

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <Grid container style={{ marginTop: isMobile ? '7vh' : '25vh' }}>
      {isMobile ? <DepNameBar id={id} department={department}></DepNameBar> : null}

      {isMobile ? null : (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            {department?.name} PRESENTATION
          </Typography>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: 'black',
              height: '2px',
              width: '90%',
              margin: { xs: '10px auto', sm: '10px auto' }
            }}
          />
        </Grid>
      )}

      <Grid container style={{ paddingTop: '10px', justifyContent: 'center' }}>
        {/* DepButtons on the left */}
        {isMobile ? null : (
          <Grid item xs={12} md={2} style={{ paddingRight: '20px' }}>
            <DepButtons id={id} />
          </Grid>
        )}

        {presentation &&
          (isMobile ? (
            <Button onClick={() => openPDF(presentation)}> Presentation? Please Click here</Button>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={8}
              spacing={2}
              paddingLeft="4vh"
              paddingRight="4vh"
              paddingTop="3vh"
              style={{
                alignItems: 'center',
                justifyItems: 'center'
              }}
            >
              <iframe title="Publications PDF" src={presentation} width="100%" height="800px" />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}

export default Presentation;
