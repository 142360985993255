import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AlumniHome from './alumni/Home/AlumniHome';
import Directory from './alumni/Directory';
import Membership from './alumni/Membership';
import ANews from './alumni/ANews';
import AlumniGallery from './alumni/alumniGallery/AlumniGallery';
import AlumniContact from './alumni/AlumniContact';
import AlumniNavbar from './alumni/AlumniNavBar';
import Events from './alumni/alumniGallery/AlumniGalleryEvent';
import AlumniFooter from './alumni/Home/AlumniFooter';

function Alumni() {
  return (
    <>
      {/* <ScrollNavbar /> */}

      <AlumniNavbar />
      <Routes>
        <Route path="/" element={<AlumniHome />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/news" element={<ANews />} />
        <Route path="/gallery" element={<AlumniGallery />} />
        <Route path="/gallery/:id" element={<Events />} />,
        <Route path="/contact" element={<AlumniContact />} />
      </Routes>
      {/* <Footer /> */}
      <AlumniFooter />
    </>
  );
}

export default Alumni;
