import React from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import img1 from './principal.jpg';
import principalData from './principalData.json';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import { useMediaQuery } from '@mui/material';
import AHeader from '../components/AHeader';
import colorConfig2 from 'app/color/colorConfig2';

const { smalltext } = colorConfig2;

const Director = () => {
  const { principal } = principalData;
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div>
      <AHeader header={'MESSAGE FROM PRINCIPAL'} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Card
            sx={{
              width: '95%',
              marginLeft: isMobile ? 0 : '50px'
            }}
          >
            <CardContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                  <img
                    src={img1}
                    alt="Director"
                    style={{ height: '45vh', width: '100%', objectFit: 'cover', marginTop: '4px' }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Georgia',
                      color: 'black',
                      marginTop: '10px',
                      border: '3px solid #ccc',
                      padding: '10px',
                      borderRadius: '5px',
                      marginBottom: { xs: '10px', md: '0' },
                      fontSize: '22px'
                    }}
                  >
                    Dr. NANDEESHWAR D.B.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography
                    style={{
                      color: smalltext,
                      textAlign: 'justify',
                      fontSize: '18px',
                      lineHeight: '1.4'
                    }}
                  >
                    <strong>{principal?.message1}</strong>
                    <br />
                    <br />
                    {principal?.message2}
                  </Typography>
                  <Typography
                    style={{
                      color: smalltext,
                      textAlign: 'justify',
                      fontSize: '18px',
                      marginTop: '10px',
                      lineHeight: '1.4'
                    }}
                  >
                    {principal?.message3}
                    <br />
                    {principal?.message4}
                    <br />
                    {principal?.message5}
                    <br />
                    {principal?.message6}
                    <br />
                    <br />
                    {principal?.message7}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} style={{ paddingLeft: '4vh' }}>
          <VerticalNewsBanner />
        </Grid>
      </Grid>
    </div>
  );
};
export default Director;
