import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import Vision1 from './Vision1';
import Mission from './Mission';
import AHeader from '../components/AHeader';
import colorConfig2 from 'app/color/colorConfig2';

const { backgroundColor } = colorConfig2;

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: backgroundColor
  },
  '.MuiTab-textColorInherit': {
    color: backgroundColor
  }
}));

const VisionandMission = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mobileStyles = {
    padding: isMobile ? '10px' : '30px',
    marginLeft: '10px',
    marginRight: '10px'
    // marginTop: '120px'
  };

  const tabsData = [
    {
      id: 0,
      label: 'Vision',
      content: '<p>vision</p>',
      heading: 'bjhb',
      vision: {
        heading: 'vihuhsion'
      }
    },
    {
      id: 1,
      label: 'Mission',
      content: '<p>mission</p>',
      mission: {
        heading: 'af'
      }
    }
  ];

  return (
    <div>
      <AHeader header={'VISION AND MISSION'} />
      <div style={mobileStyles}>
        {isMobile ? (
          <div>
            <CustomTabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="darkorange"
              orientation="vertical"
            >
              {tabsData.map((tab) => (
                <Tab key={tab.id} label={tab.label} value={tab.id} />
              ))}
            </CustomTabs>
            <div style={{ marginLeft: '10px', marginTop: '10px', fontSize: '20px' }}>
              {tabsData.map(
                (tab) =>
                  activeTab === tab.id && (
                    <div key={tab.id}>
                      {tab.vision ? <Vision1 vision1Data={tab.vision} /> : null}
                      {tab.mission ? <Mission missionData={tab.mission} /> : null}
                    </div>
                  )
              )}
            </div>
          </div>
        ) : (
          <Card style={{ margin: '20px', padding: '20px' }}>
            <CustomTabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="darkorange"
            >
              {tabsData.map((tab) => (
                <Tab key={tab.id} label={tab.label} value={tab.id} />
              ))}
            </CustomTabs>
            <div>
              {tabsData.map(
                (tab) =>
                  activeTab === tab.id && (
                    <div key={tab.id} style={{ marginLeft: '10px' }}>
                      {tab.vision ? <Vision1 vision1Data={tab.vision} /> : null}
                      {tab.mission ? <Mission missionData={tab.mission} /> : null}
                    </div>
                  )
              )}
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default VisionandMission;
