const colorConfig2 = {
    backgroundColor: '#E8D8C4',
    textColor: '#102C57',
    // Add more colors as needed
    primaryColor: '#1976d2',
    secondaryColor: '#f50057',
    navbarColor: '#102C57',
    bigtext : '#000000',
    smalltext : '#8B8C89',
    small: '#0C0C0C',
    big: '#102C57'
  };
  
  export default colorConfig2;