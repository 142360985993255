import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MenuBookSharpIcon from '@mui/icons-material/MenuBookSharp';
import { Link, useNavigate } from 'react-router-dom';

const drawerButtonStyles = {
  color: '#1e558d',
  fontSize: '18px',
  '&:hover': {
    color: '#69b6b1',
    backgroundColor: 'transparent'
  }
};

const DepDrawer = ({ id }) => {
  const navigate = useNavigate();
  console.log('id', id);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleNavigation = (route) => {
    setOpenDrawer(false);
    navigate(route);
  };

  return (
    <>
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem
            component={Link}
            to={`/departments/overview/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Overview</Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            component={Link}
            to={`/departments/hod/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>HOD</Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            component={Link}
            to={`/departments/faculty/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Faculty</Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem
            component={Link}
            to={`/departments/activities/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Activities</Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem
            component={Link}
            to={`/departments/awards/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Awards</Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem
            component={Link}
            to={`/departments/publications/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Publications</Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem
            component={Link}
            to={`/departments/presentation/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Presentation</Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem
            component={Link}
            to={`/departments/dissertation/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Dissertation</Button>
              </Typography>
            </ListItemText>
          </ListItem>

          {/* <ListItem
            component={Link}
            to={`/departments/syllabus/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Syllabus</Button>
              </Typography>
            </ListItemText>
          </ListItem> */}

          <ListItem
            component={Link}
            to={`/departments/gallery/${id}`}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>
              <Typography>
                <Button sx={drawerButtonStyles}>Gallery</Button>
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuBookSharpIcon style={{ fontSize: '2em' }} />
      </IconButton>
    </>
  );
};

export default DepDrawer;
