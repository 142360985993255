import React from 'react';
import DepButtons from './DepButtons';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import { useTheme, useMediaQuery, Grid, Typography, Divider } from '@mui/material';
import director from './image.png';
import { useParams } from 'react-router-dom';

import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import { useEffect } from 'react';
import colorConfig2 from 'app/color/colorConfig2';
import { useState } from 'react';
import { getFacultyDetailsByDept } from '../BackOfiice/components/UtilityFunctions';
import BDCH_GIF from 'assets/BDCH_GIF.gif';

function HodMessage() {
  const { bigtext, textColor, smalltext, small, big } = colorConfig2;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [facultyArray, setFacultyArray] = useState([]);
  const [facultydata, setFacultyData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getFacultyDetailsByDept(setFacultyArray, id, setLoading);
  }, [id]);

  useEffect(() => {
    setFacultyData(facultyWithPriorityOne);
  }, [facultyArray]);

  const facultyWithPriorityOne = facultyArray.find(
    (facultyMember) => facultyMember.prioritywise === 1
  );

  const department = departmentData[id];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!department) {
    return <div style={{ marginTop: '40vh' }}>Department not found</div>;
  }

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <img src={BDCH_GIF} alt="Loading..." />
      </div>
    );
  }

  return (
    <Grid container style={{ marginTop: isMobile ? '8vh' : '28vh' }}>
      {isMobile ? <DepNameBar id={id} department={department}></DepNameBar> : null}

      {isMobile ? null : (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            {department?.name} HOD
          </Typography>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: 'black',
              height: '2px',
              width: '90%',
              margin: { xs: '10px auto', sm: '10px auto' }
            }}
          />
        </Grid>
      )}

      <Grid container style={{ paddingTop: '10px', justifyContent: 'center' }}>
        {/* Left side with DepButtons */}
        {isMobile ? null : (
          <Grid item xs={12} md={2} style={{ paddingRight: '20px' }}>
            <DepButtons id={id} />
          </Grid>
        )}

        {/* Middle grid with image, title, and description */}
        <Grid container item xs={12} md={8} spacing={2}>
          {/* Left side (30%) */}
          <Grid item xs={12} md={4}>
            <img
              src={facultydata?.facProfile ? facultydata?.facProfile : director}
              alt={'HOD'}
              style={{
                width: '95%',
                height: 'auto',
                border: '2px solid black',
                marginBottom: '2vh',
                fontWeight: 'bold'
              }}
            />

            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '5px' }}>
              <Typography variant="subtitle1" sx={{ color: textColor }}>
                {department?.HODname}
              </Typography>
              <Typography variant="caption" sx={{ color: textColor }}>
                {id == 'ORAL_MEDICINE'
                  ? 'Professor & Head, Vice Principal'
                  : id == 'PROSTHODONTICS'
                  ? 'Professor & Head, Principal'
                  : 'Professor & Head'}
              </Typography>
            </Grid>
          </Grid>

          {/* Right side (70%) */}
          <Grid
            item
            xs={12}
            md={8}
            style={{
              marginTop: '20px',
              paddingLeft: isMobile ? null : '20px',
              padding: isMobile ? '4vh' : null
            }}
          >
            {department?.HODMSG?.map((item, index) => (
              <div key={index}>
                <Typography
                  style={{
                    fontFamily: 'georgia',
                    fontSize: '20px',
                    textAlign: 'left',
                    marginBottom: item?.addSpace ? '20px' : '0px'
                  }}
                >
                  <div
                    style={{
                      textAlign: 'left',
                      fontWeight: item?.bold ? 'bold' : 'none',
                      fontSize: '15px',
                      color: bigtext
                    }}
                  >
                    {item?.heading}
                  </div>
                  <div
                    style={{
                      textAlign: 'justify',
                      fontWeight: item?.contentBold ? 'bold' : 'none',
                      fontSize: '15px',
                      color: big
                    }}
                  >
                    {item?.content}
                  </div>
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>

        {/* Right side with VerticalNewsBanner */}
        {/* <Grid item xs={12} md={3} style={{ paddingLeft: '6vh' }}>
        <VerticalNewsBanner />
      </Grid> */}
      </Grid>
    </Grid>
  );
}

export default HodMessage;
