import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addBdchContact = (messageRequest) => {
  const urlPath = `/bdchcontact/addContacts`;
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: messageRequest
  });
};

export const viewBapujiContact = (authToken) => {
  const urlPath = `/bdchcontact/getAllContacts`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};

export const removeContact = (id, authToken) => {
  const urlPath = `/bdchcontact/removeContacts/${id}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};
