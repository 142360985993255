import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import colorConfig2 from 'app/color/colorConfig2';
import { color } from 'echarts';

function DepButtons({ id }) {
  const { navbarColor } = colorConfig2;
  const buttonColor = navbarColor;
  const location = useLocation();
  const currentRoute = location.pathname;

  const isButtonSelected = (buttonRoute) => currentRoute === `/departments${buttonRoute}/${id}`;

  return (
    <Grid
      style={{
        height: 'auto',
        paddingLeft: '20px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid
        style={{
          backgroundColor: navbarColor,
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography color="whitesmoke">Department Details</Typography>
      </Grid>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginTop: '10px'
        }}
      >
        <Button
          component={Link}
          to={`/departments/overview/${id}`}
          variant={isButtonSelected('/overview') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Overview</Typography>
        </Button>

        <Button
          component={Link}
          to={`/departments/hod/${id}`}
          variant={isButtonSelected('/hod') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>HOD</Typography>
        </Button>

        <Button
          component={Link}
          to={`/departments/faculty/${id}`}
          variant={isButtonSelected('/faculty') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Faculty</Typography>
        </Button>

        <Button
          component={Link}
          to={`/departments/activities/${id}`}
          variant={isButtonSelected('/activities') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Activities</Typography>
        </Button>

        <Button
          component={Link}
          to={`/departments/awards/${id}`}
          variant={isButtonSelected('/awards') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Awards</Typography>
        </Button>

        <Button
          component={Link}
          to={`/departments/publications/${id}`}
          variant={isButtonSelected('/publications') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Publications</Typography>
        </Button>

        <Button
          component={Link}
          to={`/departments/presentation/${id}`}
          variant={isButtonSelected('/presentation') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Presentation</Typography>
        </Button>

        <Button
          component={Link}
          to={`/departments/dissertation/${id}`}
          variant={isButtonSelected('/dissertation') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Dissertation</Typography>
        </Button>

        {/* <Button
          component={Link}
          to={`/departments/syllabus/${id}`}
          variant={isButtonSelected('/syllabus') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Syllabus</Typography>
        </Button> */}

        <Button
          component={Link}
          to={`/departments/gallery/${id}`}
          variant={isButtonSelected('/gallery') ? 'outlined' : 'text'}
          sx={{ color: buttonColor }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Gallery</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default DepButtons;
