import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addDirectory = (aluminiDirectoryRequest) => {
  const urlPath = `/alumnidirectory/addDirectory`;
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: aluminiDirectoryRequest
  });
};

export const updateDirectory = (aluminiDirectoryRequest, id) => {
  const urlPath = `/alumnidirectory/updateDirectory/${id}`;
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: aluminiDirectoryRequest
  });
};

export const getAllDirectory = () => {
  const urlPath = `/alumnidirectory/getAllDirectoryDetails`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};
