import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';

function ResponsiveCard({ cover, title, description, colorConfig }) {
  const { backgroundColor, textColor, bigtext, big, small } = colorConfig;
  return (
    <Card style={{ height: 650, backgroundColor: backgroundColor }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="50"
          image={cover}
          alt="green iguana"
          style={{ objectFit: 'fill', height: '250px' }}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ color: big, fontWeight: 'bold' }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ color: small, overflow: 'auto', fontWeight: 'normal' }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ResponsiveCard;
