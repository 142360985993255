import React from 'react';
import {
  useMediaQuery,
  Grid,
  Typography,
  useTheme,
  Divider,
  CardContent,
  Card,
  CardActionArea
} from '@mui/material';

import galleryData from '../alumni/alumniGallery/alumniGalleryData.json';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function NaacGallery() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (i) => {
    navigate(`/iqac/naacgallery/events/${i}`);
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '550px' }}>
      {galleryData?.map((image, index) => (
        <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
          {/* <Card
            sx={{
              maxWidth: 320,
              margin: '10px',
              height: '90%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
              }
            }}
            onClick={() => handleCardClick(image.title)}
          >
            <CardActionArea>
              <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                  src={image.src}
                  // onClick={() => openImageViewer(index)}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    margin: '2px'
                  }}
                  alt={image.alt}
                />
                <Typography>{image.alt}</Typography>
              </CardContent>
            </CardActionArea>
          </Card> */}
        </Grid>
      ))}
    </Grid>
  );
}

export default NaacGallery;
