import { Grid } from '@mui/material';
import React from 'react';

function Panoroma() {
  return (
    <Grid xs={12} style={{ marginTop: '8vh' }}>
      <iframe
        src="https://www.easytourz.com/BT-EmabedTour/all/35ab99def300ee07"
        width="100%"
        height="550"
        frameborder="0"
        title='panaroma_Screen'
        style={{ border: 0 }}
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      />
    </Grid>
  );
}

export default Panoroma;
