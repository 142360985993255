import React from 'react';
import { useMediaQuery, Grid, Typography, useTheme, Divider } from '@mui/material';
import DepButtons from './DepButtons';
import DepNameBar from './DepNameBar';
import departmentData from './departmentData.json';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import colorConfig2 from 'app/color/colorConfig2';
import CarosuelPage from './carosuel';

const Overview = (props) => {
  const { bigtext } = colorConfig2;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();

  const department = departmentData[id];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!department) {
    return <div style={{ marginTop: '40vh' }}>Department not found</div>;
  }

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: 'transparent',
          color: 'white',
          border: 'none',
          padding: '10px',
          cursor: 'pointer',
          zIndex: 1
        }}
      >
        &lt;
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: 'transparent',
          color: 'white',
          border: 'none',
          padding: '10px',
          cursor: 'pointer',
          zIndex: 1
        }}
      >
        &gt;
      </button>
    );
  };

  return (
    <Grid container style={{ marginTop: isMobile ? '8vh' : '28vh' }}>
      {isMobile ? <DepNameBar id={id} department={department}></DepNameBar> : null}

      {isMobile ? null : (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            {department?.name} OVERVIEW
          </Typography>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: 'black',
              height: '2px',
              width: '90%',
              margin: { xs: '10px auto', sm: '10px auto' }
            }}
          />
        </Grid>
      )}

      <Grid container style={{ paddingTop: '10px', justifyContent: 'center' }}>
        {/* DepButtons on the left */}
        {isMobile ? null : (
          <Grid item xs={12} md={2} style={{ paddingRight: '20px' }}>
            <DepButtons id={id} />
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          <Grid container direction="column">
            <Grid item style={{ objectFit: 'contain' }}>
              {department?.image ? (
                <img
                  src={department.image}
                  alt="Department"
                  style={{
                    maxWidth: '100%',
                    height: 'auto'
                  }}
                />
              ) : department?.carouselData ? (
                <CarosuelPage carouselData={department.carouselData} />
              ) : (
                <div>No image or carousel available</div>
              )}
            </Grid>

            <Grid item xs={12} style={{ marginTop: '20px', padding: isMobile ? '10px' : null }}>
              {department?.overview?.map((item, index) => (
                <div key={index}>
                  <Typography
                    style={{
                      fontFamily: 'georgia',
                      fontSize: '20px',
                      textAlign: 'left',
                      marginBottom: item?.addSpace ? '20px' : '0px'
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'left',
                        fontWeight: item?.bold ? 'bold' : 'none',
                        color: bigtext
                      }}
                    >
                      {item?.heading}
                    </div>
                    <div
                      style={{
                        textAlign: 'justify',
                        fontWeight: item?.contentBold ? 'bold' : 'none'
                      }}
                    >
                      {item?.content}
                    </div>
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Overview;
