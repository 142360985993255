import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';
import certificate from './certificate.jpg';
import govApprovalPdf from './GovernmentApp.pdf';
import rguhsAffiliationPdf from './Affiliation23-24.pdf';


const Affiliation = () => {
  const cardContent = [
    {
      title: 'NAAC Accreditation Certificate',
      description: 'View NAAC Accreditation Certificate',
      imageUrl: certificate,
      isImage: true
    },
    {
      title: 'Government Approval',
      description: 'View Government Approval',
      pdfUrl: govApprovalPdf,
      isImage: false
    },
    {
      title: 'Affiliation for BDS and MDS Courses',
      description: 'View Affiliation for BDS and MDS Courses',
      pdfUrl: rguhsAffiliationPdf,
      isImage: false
    }
  ];

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openImage = (imageUrl) => {
    const win = window.open();
    const img = win.document.createElement('img');
    img.src = imageUrl;
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.objectFit = 'contain';
    win.document.body.appendChild(img);
  };

  const openPdf = (pdfUrl) => {
    const win = window.open();
    win.document.write(
      `<iframe src="${pdfUrl}" style="width:100%; height:100%; border:none;"></iframe>`
    );
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12}>
        <AHeader header={'AFFILIATION'} />
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
            marginLeft: '8vh'
          }}
        >
          <Grid item xs={12} md={7}>
            {cardContent.map((content, index) => (
              <Accordion
                key={index}
                sx={{
                  backgroundColor: '#0180A6',
                  width: '90%',
                  marginBottom: '30px'
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Typography sx={{ fontSize: '25px', fontFamily: 'bold', color: 'whitesmoke' }}>
                    {content.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: isMobile ? '16px' : '20px',
                      cursor: 'pointer',
                      fontStyle: 'italic',
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: 'blue'
                      }
                    }}
                    onClick={() =>
                      content.isImage ? openImage(content.imageUrl) : openPdf(content.pdfUrl)
                    }
                  >
                    {content.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <VerticalNewsBanner />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // <Container>
    //   <AHeader header={'AFFILIATION'} />

    //   <div
    //     style={{
    //       textAlign: 'center',
    //       marginBottom: '80px',
    //       marginLeft: isMobile ? '50px' : '100px'
    //     }}
    //   >
    //     <Grid
    //       container
    //       justifyContent="center"
    //       spacing={isMobile ? 2 : 3}
    //       sx={{ marginTop: '20px' }}
    //     >
    //       <Grid item xs={12} md={9}>
    //         {cardContent.map((content, index) => (
    //           <Accordion
    //             key={index}
    //             sx={{
    //               backgroundColor: '#0180A6',
    //               width: '90%',
    //               marginBottom: '30px'
    //             }}
    //           >
    //             <AccordionSummary
    //               expandIcon={<ArrowDropDownIcon />}
    //               aria-controls={`panel${index + 1}-content`}
    //               id={`panel${index + 1}-header`}
    //             >
    //               <Typography sx={{ fontSize: '25px', fontFamily: 'bold', color: 'whitesmoke' }}>
    //                 {content.title}
    //               </Typography>
    //             </AccordionSummary>
    //             <AccordionDetails>
    //               <Typography
    //                 variant="body1"
    //                 sx={{
    //                   fontSize: isMobile ? '16px' : '20px',
    //                   cursor: 'pointer',
    //                   fontStyle: 'italic',
    //                   textDecoration: 'none',
    //                   color: 'inherit',
    //                   '&:hover': {
    //                     textDecoration: 'underline',
    //                     color: 'blue'
    //                   }
    //                 }}
    //                 onClick={() =>
    //                   content.isImage ? openImage(content.imageUrl) : openPdf(content.pdfUrl)
    //                 }
    //               >
    //                 {content.description}
    //               </Typography>
    //             </AccordionDetails>
    //           </Accordion>
    //         ))}
    //       </Grid>

    //       <Grid item xs={12} md={3}></Grid>
    //     </Grid>
    //   </div>
    // </Container>
  );
};

export default Affiliation;
