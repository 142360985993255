import React, { useEffect } from 'react';
import { Grid, Typography, Divider, Card, CardContent } from '@mui/material';
import img1 from './director.jpg';
import directorData from './directorData.json';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';

const Director = () => {
  const { director } = directorData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: { xs: '100px', md: '200px' },
          marginLeft: '20px',
          textAlign: 'center'
        }}
      >
        <Grid
          item
          xs={12}
          md={8} // 70% of the screen on the left
          sx={{
            justifySelf: 'center',
            marginTop: { xs: '100px', md: '20px' },
            padding: { xs: '10px', md: '0px' }
          }}
        >
          <Typography variant="h4" sx={{ padding: { xs: '10px', md: '0px' } }}>
            MESSAGE FROM DIRECTOR
          </Typography>
          <Divider style={{ width: '100px' }} />
          <Card
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifySelf: 'center',
              padding: { xs: '10px', md: '20px' },
              width: '100%', // 100% width within its container
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.1s, box-shadow 0.1s',
              '&:hover': {
                transform: 'scale(1.01)',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)'
              }
            }}
          >
            <Grid
              sx={{
                marginBottom: { xs: '20px', md: '0' },
                height: { xs: 'auto', md: 400 },
                width: { xs: '80%', md: '100%' }
              }}
            >
              <img
                src={img1}
                alt="Director Image"
                style={{
                  height: '80%',
                  width: '80%',
                  objectFit: 'cover',
                  borderRadius: { xs: '5px', md: '0' }
                }}
              />
              <Typography
                variant="h6"
                style={{
                  fontFamily: 'Georgia',
                  color: 'black',
                  marginTop: { xs: '10px', md: '0' },
                  border: '4px solid #ccc',
                  padding: '10px',
                  borderRadius: '5px'
                }}
              >
                {director?.name}
              </Typography>
            </Grid>

            <CardContent sx={{ marginLeft: { xs: '0', md: '20px' } }}>
              <Typography
                variant="h5"
                style={{
                  fontFamily: 'Georgia',
                  color: 'black',
                  marginBottom: { xs: '10px', md: '20px' }
                }}
              >
                {director?.message1}
              </Typography>
              <Typography variant="h5" style={{ fontFamily: 'Georgia', color: 'black' }}>
                {director?.message2}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={3} // 30% of the screen on the right
          sx={{
            marginTop: { xs: '10px', md: '20px' },
            textAlign: 'center'
          }}
        >
          <VerticalNewsBanner />
        </Grid>
      </Grid>
    </div>
  );
};

export default Director;
