import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const getDepartmentDetails = () => {
  const urlPath = `/department/getAllDepartmentDetails`;

  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const getDepartmentDetailsByDeptType = (deptType) => {
  const urlPath = `/department/getDepartmentDetails/${deptType}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const getFacultyDetailsByDeptType = (deptType) => {
  const urlPath = `/department/getAllFacultyByDeptType/${deptType}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const addFaculty = (facultyRequest, deptType, authToken) => {
  const urlPath = `/department/${deptType}/addFaculty`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: facultyRequest,
    additionalHeader: additionalHeader
  });
};

export const updateFaculty = (facultyRequest, deptType, facultyId, authToken) => {
  const urlPath = `/department/${deptType}/facultyUpdate/${facultyId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: facultyRequest,
    additionalHeader: additionalHeader
  });
};

export const removeFacultyById = (deptType, facultyId, authToken) => {
  const urlPath = `/department/removeFacultyByDeptTypeandFacultyId/${deptType}/${facultyId}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath,
    additionalHeader: additionalHeader
  });
};

export const getPublicationsByDeptType = (deptType) => {
  const urlPath = `/department/getAllPublicationsByDeptType/${deptType}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const updatePublications = (publicationRequest, deptType, authToken) => {
  const urlPath = `/department/publicationsUpdate/${deptType}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: publicationRequest,
    additionalHeader: additionalHeader
  });
};

export const getPresentationsByDeptType = (deptType) => {
  const urlPath = `/department/getAllpresentationByDeptType/${deptType}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const updatePresentations = (publicationRequest, deptType, authToken) => {
  const urlPath = `/department/presentationUpdate/${deptType}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: publicationRequest,
    additionalHeader: additionalHeader
  });
};

export const getAwardsByDeptType = (deptType) => {
  const urlPath = `/department/getAllAwardsByDeptType/${deptType}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const updateAwards = (awardsRequest, deptType, authToken) => {
  const urlPath = `/department/awardsUpdate/${deptType}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: awardsRequest,
    additionalHeader: additionalHeader
  });
};

export const getDissertationsByDeptType = (deptType) => {
  const urlPath = `/department/getAllDissertationsByDeptType/${deptType}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const updateDissertation = (dissertationRequest, deptType, authToken) => {
  const urlPath = `/department/dissertationUpdate/${deptType}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: dissertationRequest,
    additionalHeader: additionalHeader
  });
};

export const syllabusUpdate = (syllabusRequest, deptType, authToken) => {
  const urlPath = `/department/syllabusUpdate/${deptType}`;
  const additionalHeader = {
    Authorization: `Bearer ${authToken}`
  };
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: syllabusRequest,
    additionalHeader: additionalHeader
  });
};
