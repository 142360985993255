import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Modal,
  CardContent,
  Card,
  CardActionArea,
  Box,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import imageData from './libimagegallerydata.json';

function ImageGallery() {
  const [state, setState] = useState(false);
  const [galleryData, setGalleryData] = useState(imageData);
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: '240px', marginTop: state ? '20vh' : 0 }}>
        {galleryData?.map((image, index) => (
          <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
            <Card
              sx={{
                maxWidth: 320,
                margin: '10px',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
                }
              }}
              onClick={() => handleCardClick(image)}
            >
              <CardActionArea>
                <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                  <img
                    src={image.src}
                    style={{
                      width: '100%',
                      height: '250px',
                      objectFit: 'contain',
                      margin: '2px',
                      borderRadius: '4px'
                    }}
                    alt={image.alt}
                  />
                  <Typography textAlign="center">{image.alt}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal open={!!selectedImage} onClose={handleClose} aria-labelledby="image-modal">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : '60%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          {selectedImage && (
            <>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'grey.500'
                }}
              >
                <CloseIcon />
              </IconButton>

              <img
                src={selectedImage.src}
                alt={selectedImage.alt}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '4px',
                  maxHeight: '80vh',
                  objectFit: 'contain'
                }}
              />
              <Typography variant="h6" textAlign="center" sx={{ mt: 2 }}>
                {selectedImage.alt}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ImageGallery;
