import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Vision from './vision.webp';
import Mission from './mission.webp';

const VmSection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Data = [
    {
      heading: 'Our Mission',
      image: Mission,
      text: [
        'Learner centered Dental Education',
        'Patient Centered Service',
        'Community oriented Research',
        'Strong Community Relationship',
        'Serve the Under Served',
        'Meet the Regional, National and Global Dental Educational needs',
        'Inter organizational needs',
        'Strategic future oriented planning',
        'Excellence in knowledge, skills and services',
        'Professionalism in Management',
        'Open Organizational Climate'
      ]
    },
    {
      heading: 'Our Vision',
      image: Vision,
      text: [
        'Single Window Delivery of Total Oral Healthcare Needs',
        'Total Quality Management in Service & Education',
        'Unique Work Culture in alleviating Human Oral Sufferings',
        'To Train General, Specialized & Allied Professional Personnel to meet Regional & National Oral Health Care Services',
        'Work Relentlessly to contribute to Global Oral Health Care Knowledge & Skills',
        'Be efficient, effective, community acceptable and excel in service, education & research',
        'To Impart Knowledge & Interact with Organizations of Similar Interest',
        'Fostering Global Competencies, inculcating value system among learners',
        'Promote use of Technology of Relevance',
        'To Induce Paradigm Shift in Community that many oral diseases are preventable, curable & affordable',
        'Reach the unreachable with Awareness, Education & Service',
        'Serve the under served'
      ]
    }
  ];

  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '10px',
    height: isSmallScreen ? '850px' : '700px',
    display: 'flex',
    flexDirection: 'column'
  };

  const cardHeaderStyle = {
    color: '#333',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center'
  };

  const cardTextStyle = {
    fontSize: '15px',
    marginBottom: '10px',
    textAlign : 'left'
  };

  return (
    <Grid container spacing={1} justifyContent="center" marginTop="20px" padding="20px">
      {Data.map((data, index) => (
        <Grid item xs={12} sm={6} md={6} key={index}>
          <Card style={cardStyle}>
            <CardContent>
              <Typography variant="h1" style={cardHeaderStyle}>
                {data.heading}
              </Typography>
              <CardMedia
                component="img"
                image={data.image}
                alt={data.heading}
                sx={{
                  maxWidth: '100%',
                  width: '40%',
                  maxHeight: '250px',
                  marginBottom: '20px',
                  marginLeft: '30%',
                  display: 'flex',
                  justifyContent: 'left'
                }}
              />
              {data.text.map((line, i) => (
                <Typography key={i} style={cardTextStyle}>
                  • {line}
                </Typography>
              ))}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default VmSection;
