import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';
import colorConfig2 from 'app/color/colorConfig2';

const { bigtext } = colorConfig2;

const AcademicDetails = () => {
  const cardContent = [
    {
      Slno: '1',
      Subject: 'Oral Medicine &  Radiology',
      Seat: '4'
    },
    {
      Slno: '2',
      Subject: 'Paedodontics & Preventive Dentistry',
      Seat: '6'
    },
    {
      Slno: '3',
      Subject: 'Public Health Dentistry',
      Seat: '3'
    },
    {
      Slno: '4',
      Subject: 'Oral Pathology & Microbiology',
      Seat: '2'
    },
    {
      Slno: '5',
      Subject: 'Orthodontics & Dentofacial Orthopedics',
      Seat: '6'
    },
    {
      Slno: '6',
      Subject: 'Conservative Dentistry & Endodontics',
      Seat: '6'
    },
    {
      Slno: '7',
      Subject: 'Oral & Maxillofacial Surgery',
      Seat: '6'
    },
    {
      Slno: '8',
      Subject: 'Periodontology',
      Seat: '6'
    },
    {
      Slno: '9',
      Subject: 'Prosthodontics and Crown & Bridge',
      Seat: '6'
    }
  ];

  const isMobile = useMediaQuery('(max-width:600px)');
  const contentArray = cardContent.slice(0, 9);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <AHeader header={'ACADEMIC DEATILS'} />
      <Grid container spacing={2} xs={12}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Typography variant="h5" sx={{ marginBottom: '20PX' }}>
            INTAKE OF ADMISSIONS
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          style={{ display: 'flex', flexDirection: 'column', padding: isMobile ? '3.3vh' : '5vh' }}
          spacing={2}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: isMobile ? '330px' : '95%',
              marginLeft: isMobile ? '.5vh' : '8vh',
              marginRight: '2vh',
              overflowX: 'auto'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      color: bigtext,
                      fontSize: isMobile ? '18px' : '20px',
                      fontWeight: 'bold'
                    }}
                  >
                    BDS
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      color: bigtext,
                      fontSize: isMobile ? '18px' : '20px',
                      fontWeight: 'bold'
                    }}
                  >
                    MDS
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow align="center">
                  <TableCell sx={{ textAlign: 'center', padding: '20px' }}>
                    Total BDS Intake – 100 Seats
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', padding: '20px' }}>
                    Total MDS Intake – {'  '}45 Seats
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: '20px',
              width: isMobile ? '330px' : '95%',
              marginLeft: isMobile ? '.5vh' : '8vh',
              marginRight: '2vh',
              overflowX: 'auto'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    width={isMobile ? '15%' : '20%'}
                    sx={{
                      paddingLeft: '4px',
                      margin: '20px',
                      color: bigtext,
                      fontSize: isMobile ? '16px' : '18px',
                      fontWeight: 'bold'
                    }}
                  >
                    Sl. No
                  </TableCell>
                  <TableCell
                    align="center"
                    width={isMobile ? '100%' : '90%'}
                    sx={{
                      color: bigtext,
                      fontSize: isMobile ? '16px' : '18px',
                      fontWeight: 'bold'
                    }}
                  >
                    Subject
                  </TableCell>
                  <TableCell
                    align="center"
                    width={isMobile ? '20%' : '30%'}
                    sx={{
                      paddingRight: '4px',
                      color: bigtext,
                      fontSize: isMobile ? '16px' : '18px',
                      fontWeight: 'bold'
                    }}
                  >
                    Seats
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contentArray.map((content, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textAlign: isMobile ? 'left' : 'center',
                        padding: isMobile ? '1px' : '16px'
                      }}
                    >
                      {content.Subject}
                    </TableCell>
                    <TableCell align="center">{content.Seat}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          style={{ display: 'flex', justifyContent: 'center', paddingLeft: '4vh' }}
        >
          <VerticalNewsBanner />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AcademicDetails;
