// import React, { useEffect } from 'react';
// import { Grid, Typography, Container } from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import principalData from './principalData.json';

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import AHeader from '../components/AHeader';

// const Committees = () => {
//   const { cardContent } = principalData;

//   const firstColumnContent = cardContent.slice(0, 15);
//   const secondColumnContent = cardContent.slice(15);
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <>
//       <AHeader header={'COMMITTEES'} />
//       <Container>
//         <Grid container spacing={{ xs: 4, md: 3 }} sx={{}}>
//           <Grid item xs={12} md={6} sx={{}}>
//             {firstColumnContent.map((content, index) => (
//               <Accordion
//                 key={index}
//                 sx={{ width: { xs: '90%', sm: '95%' }, marginLeft: { xs: '20px' } }}
//               >
//                 <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
//                   <Typography
//                     sx={{ fontSize: '19px', fontWeight: '20' }}
//                     className="hover-underline"
//                   >
//                     {content.title}
//                   </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails></AccordionDetails>
//               </Accordion>
//             ))}
//           </Grid>
//           <Grid item xs={12} md={6} sx={{}}>
//             {secondColumnContent.map((content, index) => (
//               <Accordion
//                 key={index}
//                 sx={{ width: { xs: '90%', sm: '95%' }, marginLeft: { xs: '20px' } }}
//               >
//                 <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
//                   <Typography
//                     sx={{ fontSize: '19px', fontWeight: '20' }}
//                     className="hover-underline"
//                   >
//                     {content.title}
//                   </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails></AccordionDetails>
//               </Accordion>
//             ))}
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default Committees;

// import React, { useEffect } from 'react';
// import { Grid, Typography, Container, Link } from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import principalData from './principalData.json';

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import AHeader from '../components/AHeader';

// const Committees = () => {
//   const { cardContent } = principalData;

//   const firstColumnContent = cardContent.slice(0, 16);
//   const secondColumnContent = cardContent.slice(16);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <>
//       <AHeader header={'COMMITTEES'} />
//       <Container>
//         <Grid container spacing={{ xs: 4, md: 3 }}>
//           <Grid item xs={12} md={6}>
//             {firstColumnContent.map((content, index) => (
//               <Accordion
//                 key={index}
//                 sx={{ width: { xs: '90%', sm: '95%' }, marginLeft: { xs: '20px' } }}
//               >
//                 <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
//                   <Typography
//                     sx={{ fontSize: '19px', fontWeight: '20' }}
//                     className="hover-underline"
//                   >
//                     {content.title}
//                   </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   {content.fileUrl ? (
//                     <Link href={content.fileUrl} download target="_blank" rel="noopener noreferrer">
//                       Download Document
//                     </Link>
//                   ) : (
//                     <Typography>{content.description || 'No document available.'}</Typography>
//                   )}
//                 </AccordionDetails>
//               </Accordion>
//             ))}
//           </Grid>

//           <Grid item xs={12} md={6}>
//             {secondColumnContent.map((content, index) => (
//               <Accordion
//                 key={index}
//                 sx={{ width: { xs: '90%', sm: '95%' }, marginLeft: { xs: '20px' } }}
//               >
//                 <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
//                   <Typography
//                     sx={{ fontSize: '19px', fontWeight: '20' }}
//                     className="hover-underline"
//                   >
//                     {content.title}
//                   </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   {content.fileUrl ? (
//                     <Link href={content.fileUrl} download target="_blank" rel="noopener noreferrer">
//                       Download Document
//                     </Link>
//                   ) : (
//                     <Typography>{content.description || 'No document available.'}</Typography>
//                   )}
//                 </AccordionDetails>
//               </Accordion>
//             ))}
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default Committees;

import React, { useEffect } from 'react';
import { Grid, Typography, Container, Link } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import principalData from './principalData.json';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AHeader from '../components/AHeader';

const Committees = () => {
  const { cardContent } = principalData;

  const firstColumnContent = cardContent.slice(0, 16);
  const secondColumnContent = cardContent.slice(16);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AHeader header={'COMMITTEES'} />
      <Container>
        <Grid container spacing={{ xs: 4, md: 3 }}>
          <Grid item xs={12} md={6}>
            {firstColumnContent.map((content, index) => (
              <Accordion
                key={index}
                sx={{ width: { xs: '90%', sm: '95%' }, marginLeft: { xs: '20px' } }}
              >
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                  <Typography
                    sx={{ fontSize: '19px', fontWeight: '20' }}
                    className="hover-underline"
                  >
                    {content.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {content.fileUrl ? (
                    <a href={content.fileUrl} target="_blank" rel="noopener noreferrer">
                      View Document
                    </a>
                  ) : (
                    <Typography> </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            {secondColumnContent.map((content, index) => (
              <Accordion
                key={index}
                sx={{ width: { xs: '90%', sm: '95%' }, marginLeft: { xs: '20px' } }}
              >
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                  <Typography
                    sx={{ fontSize: '19px', fontWeight: '20' }}
                    className="hover-underline"
                  >
                    {content.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {content.fileUrl ? (
                    <a href={content.fileUrl} target="_blank" rel="noopener noreferrer">
                      View Document
                    </a>
                  ) : (
                    <Typography> </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Committees;
