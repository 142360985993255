// import React from 'react';
// import SimpleImageSlider from 'react-simple-image-slider';
// import image1 from './iqacbg.png';

// const images = [{ url: image1 }, { url: image1 }];

// function IqacHome() {
//   return (
//     <div>
//       <SimpleImageSlider
//         width="100%"
//         height={670}
//         images={images}
//         showBullets={false}
//         showNavs={true}
//       />
//     </div>
//   );
// }

// export default IqacHome;
import React, { useState, useEffect } from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
import image1 from './iqacbg.png';

const images = [{ url: image1 }, { url: image1 }];

function IqacHome() {
  const [sliderHeight, setSliderHeight] = useState(670);

  const updateSliderHeight = () => {
    if (window.innerWidth <= 600) {
      setSliderHeight(400);
    } else {
      setSliderHeight(670);
    }
  };

  useEffect(() => {
    updateSliderHeight();
    window.addEventListener('resize', updateSliderHeight);

    return () => {
      window.removeEventListener('resize', updateSliderHeight);
    };
  }, []);

  return (
    <div>
      <SimpleImageSlider
        width="100%"
        height={sliderHeight}
        images={images}
        showBullets={false}
        showNavs={true}
        autoPlay={true}
        slideInterval={200}
      />
    </div>
  );
}

export default IqacHome;
