const colorConfig3 = {
    backgroundColor: '#F3EDC8',
    textColor: '#A22E3B',
    // Add more colors as needed
    primaryColor: '#1976d2',
    secondaryColor: '#f50057',
    navbarColor: '#750E21',
    bigtext : '#000000',
    smalltext : '#8B8C89',
    small: '#0C0C0C',
    big: '#A22E3B'
  };
  
  export default colorConfig3;