import { Typography, Card, Link } from '@mui/material';
import React from 'react';

const Vision1 = ({ vision1Data }) => {
  return (
    <div style={{ margin: '10px' }}>
      <ol style={{ fontSize: '20px' }}>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Single window delivery of total oral healthcare needs
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Total quality management in Service & Education
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Unique work culture in alleviating human oral sufferings
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            To train general, Specialized & Allied professional personnel to meet regional &
            national oral health care services
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Work relentlessly to contribute to global oral health care knowledge & skills
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Be efficient, effective, community Acceptable and excel in service, education & research
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            To impart knowledge & interact with organizations of similar interest
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Fostering global competencies, inculcating value system among learners
          </Typography>
        </li>
        <li>
          <Typography v>Promote use of technology of relevance</Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            To induce paradigm shift in community that many oral diseases are preventable, curable &
            affordable
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>
            Reach the unreachable with awareness, education & Service
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: '20px' }}>Serve the under served</Typography>
        </li>
      </ol>
      <br />
      <Typography variant="h5">MOTTO</Typography>
      <Typography sx={{ fontSize: '20px' }}>
        “YOUR HEALTH, YOUR COMFORT, YOUR PRIVACY, YOUR TRUST”
      </Typography>
      <br />
      <Typography variant="h5">MISSION</Typography>
      <Typography sx={{ fontSize: '20px' }}>
        “TO PROVIDE FULL-SCOPE ORAL AND MAXILLOFACIAL SURGICAL CARE TO THE PEOPLE OF THE STATE”.
      </Typography>
      <br />
      <Typography variant="h5">VISION</Typography>
      <Typography sx={{ fontSize: '20px' }}>
        “TO PROVIDE SCIENTIFICALLY BASED, STATE OF THE ART ORAL AND MAXILLOFACIAL SURGERY OF THE
        HIGHEST CALIBER TO IMPROVE HEALTH AND QUALITY OF LIFE OF THE PATIENTS THEREBY CONTRIBUTING
        TO THE NATIONAL AND GLOBAL WELFARE OF THE HUMAN LIVES.”
      </Typography>
      <Typography style={{ marginTop: '10px', textAlign: 'justify', fontSize: '20px' }}>
        The department works round the clock in providing utmost patient comfort and care. It is our
        continuous dedication for excellence, which has granted us one of the most reputable
        position in the field of oral and maxillofacial surgery throughout the state of Karnataka as
        well as India. The cases treated in our department are handled with paramount care be it pre
        or post operatively under the guidance of experienced and well-qualified professionals. The
        wide spectrum of cases managed in our unit ranges from the field of Oral Cancers,
        Orthognathic Surgeries, Facial Traumatology, Facial Reconstruction including Cleft Lip and
        Palate, T.M. Joint Disorders and Microvascular Surgery.
      </Typography>
    </div>
  );
};

export default Vision1;
