import React, { useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import VerticalNewsBanner from '../horizontal-news/VerticalNewsBanner';
import AHeader from '../components/AHeader';
import colorConfig2 from 'app/color/colorConfig2';

const { bigtext } = colorConfig2;
const Examschedule = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <AHeader header={'EXAM SCHEDULE'} />
      <Grid container spacing={4} sx={{ marginLeft: isMobile ? '-35px' : '10px' }}>
        <Grid item spacing={3} xs={12} md={8} sm={4}>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Card
              sx={{
                transform: isMobile ? 'scale(0.95)' : 'scale(1)',
                transition: 'transform 0.3s ease',
                maxWidth: '100%'
              }}
            >
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ color: bigtext, fontSize: '20px' }}>
                        BDS Exam Schedule - Tentative
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontStyle: 'italic', color: bigtext }}>
                        August/September & March/April
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              sx={{
                transform: isMobile ? 'scale(0.95)' : 'scale(1)',
                transition: 'transform 0.3s ease',
                maxWidth: '100%'
              }}
            >
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ color: bigtext, fontSize: '20px' }}>
                        MDS Exam Schedule - Tentative
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontStyle: 'italic', color: bigtext }}>
                        July / December
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <VerticalNewsBanner />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Examschedule;
