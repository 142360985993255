import pdf from './RTI.pdf';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AHeader from '../components/AHeader';

function Overview() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const iframeWidth = isMobile ? '90%' : '60%';
  const iframeHeight = isMobile ? '400px' : '800px';

  return (
    <div>
      <AHeader header={'Right To Information Act 2005'} />
      <div
        style={{
          marginLeft: isMobile ? '10px' : '300px',
          marginTop: '20px',
          width: '100%',
          boxSizing: 'border-box'
        }}
      >
        <div style={{ width: '100%', overflowX: isMobile ? 'auto' : 'hidden' }}>
          <iframe
            src={pdf}
            title="rti_pdf"
            width={iframeWidth}
            height={iframeHeight}
            style={{ border: 'none' }}
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
