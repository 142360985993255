import React from 'react';
import { AppBar, Toolbar, Typography, useTheme, useMediaQuery } from '@mui/material';
import DepDrawer from './DepDrawer';

const DepNameBar = ({ department, id }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <AppBar
      position="static"
      style={{
        background: '#61D2FF',
        width: '100%'
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }} style={{ justifyContent: 'center' }}>
        <Typography variant="h6" style={{ color: '#14153E', textAlign: 'center' }}>
          {department?.name}
        </Typography>

         <DepDrawer id={id} />
      </Toolbar>
    </AppBar>
  );
};

export default DepNameBar;
