import { Alert, Button, Grid, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import colorConfig2 from 'app/color/colorConfig2';
const { navbarColor } = colorConfig2;

function ProfilePicUploader({ setFacProfile }) {
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [maxSizeMB, setMaxSizeMB] = useState(1);
  const [maxWidthOrHeight, setMaxWidthOrHeight] = useState(512);

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const compressAndReturnBase64 = async (e) => {
    const file = e.target.files[0];

    const options = {
      maxSizeMB: maxSizeMB,
      maxWidthOrHeight: maxWidthOrHeight
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        const base64String = reader.result;
        const fileName = file.name;

        const fileData = {
          base64: base64String,
          fileName: fileName
        };
        setFacProfile(fileData);
      };
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  return (
    <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-input"
        type="file"
        multiple
        onChange={compressAndReturnBase64}
      />
      <label htmlFor="image-input">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ backgroundColor: navbarColor, color: 'whitesmoke' }}
        >
          Upload Image
        </Button>
      </label>
    </>
  );
}

export default ProfilePicUploader;
