import {  Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import backgroundImage from '../../../assets/Bap1.jpg';
import AboutUsText from './AboutUsText';

function AboutUsHome({ colorConfig }) {
  const theme = useTheme();
  const {  textColor } = colorConfig;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Grid container xs={12} style={{ justifyContent: 'center' }}>
        {!isMobile && (
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: textColor,
              width: '100%',
              height: '10vh',
              display: 'flex',
              position: 'relative'
            }}
          />
        )}

        <Grid
          container
          item
          xs={12}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            position: 'relative',
            height: isMobile ? '40vh' : '80vh',
            objectFit: 'fill',
            zIndex: 0
          }}
        />
        {!isMobile && (
          <Grid
            container
            item
            xs={12}
            style={{
              position: 'absolute',
              transform: 'skewX(-6deg)',
              backgroundColor: 'white',
              width: '55%',
              height: '95vh',
              zIndex: 1
            }}
          >
            <AboutUsText colorConfig={colorConfig} />
          </Grid>
        )}

        {!isMobile && (
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: textColor,
              width: '100%',
              height: '10vh',
              display: 'flex',
              position: 'relative'
            }}
          />
        )}
        {isMobile && <AboutUsText colorConfig={colorConfig} />}
      </Grid>
    </>
  );
}

export default AboutUsHome;
