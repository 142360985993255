import React from 'react';
import {
  useMediaQuery,
  Grid,
  Typography,
  useTheme,
  Divider,
  CardContent,
  Card,
  CardActionArea
} from '@mui/material';

import alumniData from './alumniGalleryData.json';
import mainGallery from './mainGalleryData.json';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

function AlumniGallery() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [state, setState] = useState(false);
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    if (path === '/gallery/image') {
      setState(true);
      setGalleryData(mainGallery);
    } else {
      setState(false);
      setGalleryData(alumniData);
    }
  }, [path]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (i) => {
    navigate(`/alumni/gallery/${i}`);
  };
  return (
    <Grid container spacing={2} sx={{ marginBottom: '240px', marginTop: state ? '20vh' : 0 }}>
      {galleryData?.map((image, index) => (
        <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
          <Card
            sx={{
              maxWidth: 320,
              margin: '10px',
              height: '90%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
              }
            }}
            onClick={() => handleCardClick(image.title)}
          >
            <CardActionArea>
              <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <img
                  src={image.src}
                  style={{
                    width: '100%',
                    height: '250px',
                    objectFit: 'cover',
                    margin: '2px',
                    borderRadius: '4px'
                  }}
                  alt={image.alt}
                /> */}
                <Typography>{image.alt}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default AlumniGallery;
